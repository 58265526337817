import { Button, Tour } from 'antd';
import { selectCurrentUser, setStatusTour } from 'features/users/userSlice';
import { useChangeStatusTourMutation } from 'features/users/usersApiSlice';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CaslContext from 'utils/caslContext';
import TourContext from './TourContext';
import './style.css';
import { defineStepsForTour } from './tourUtility';

function TourComponent() {
  const { pathname } = useLocation();
  const [module, submodule] = pathname.substring(1).split('/');
  const { tourOpen, setTourOpen, getAllReferences } = useContext(TourContext);
  const { id: userId, enableTour: userEnableTour } = useSelector(selectCurrentUser);
  const [changeStatusTour] = useChangeStatusTourMutation();
  const dispatch = useDispatch();
  const [steps, setSteps] = useState([]);
  const references = getAllReferences();
  const ability = useContext(CaslContext);
  // this need happend always

  useEffect(() => {
    // if user status for tour and tour status is true (open), load steps
    if (tourOpen && userEnableTour) {
      const newSteps = defineStepsForTour(module, submodule, references, ability);
      setSteps(newSteps);
    }
    // in case user status for tour and tour status are different try to made equals
    if (tourOpen !== userEnableTour) {
      setTourOpen(userEnableTour);
    }
  }, [ability, module, references, setTourOpen, submodule, tourOpen, userEnableTour]);

  const showTotalSteps = (current, total) => (
    <span>
      {current + 1} / {total}
    </span>
  );

  const onCloseTour = async () => {
    await changeStatusTour(userId);
    // change for new status
    setTourOpen(!userEnableTour);
    dispatch(setStatusTour({ enableTour: !userEnableTour }));
  };

  return (
    <Tour
      open={tourOpen}
      onClose={onCloseTour}
      steps={steps}
      closeIcon={
        <Button type="link" style={{ padding: 0 }}>
          Omitir
        </Button>
      }
      indicatorsRender={showTotalSteps}
    />
  );
}

export default TourComponent;
