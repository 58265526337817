import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Space } from 'antd';
import TOLogo from 'assets/img/sidebar-inv-logo.svg';
import SubNavBar from 'components/layout/SubNavBar';
import NewRouteModal from 'components/NewRoute';
import PropTypes from 'prop-types';
import { useContext, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getAllIndexFromArray } from 'services/repeated-functions';
import CaslContext from 'utils/caslContext';
import LocaleContext from '../../locale/LocaleContext';
import TourContext from '../TourComponent/TourContext';
import DocumentStatus from './DocumentStatus';
import LanguageSelector from './LanguageSelector';
import Notifications from './Notifications';
import OrganizationLogo from './OrganizationLogo';
import ProfileContainer from './ProfileContainer';
import SearchContainer from './SearchContainer';
import './style.css';
import TourButton from './TourButton';

// ToDo: add in others submodules check with ability
const getInfoByModule = (module, ability, arrayRefs, addReference, i18n) => {
  let menus = [];
  let nameModule;
  // ToDo: check a better way to do this, with all other permissions/sub modules
  const allFromArray = getAllIndexFromArray(
    ['fleets', 'users', 'roles', 'organizations', 'system', 'panels'],
    ability
  );
  const allLocationsFromArray = getAllIndexFromArray(['visits', 'base'], ability);
  const allReportsFromArray = getAllIndexFromArray(['panels', 'reports'], ability);
  const allRoutesFromArray = getAllIndexFromArray(['planning', 'routes', 'jobs'], ability);
  const allFollowingFromArray = getAllIndexFromArray(['following'], ability);
  const {
    fleets: fleetIndexPath,
    users: userIndexPath,
    roles: roleIndexPath,
    organizations: organizationIndexPath,
    system: systemIndexPath,
  } = allFromArray;
  const {
    routes: routeIndexPath,
    jobs: jobIndexPath,
    planning: planningIndexPath,
  } = allRoutesFromArray;
  const { visits: visitIndexPath, base: baseIndexPath } = allLocationsFromArray;
  const { panels: panelIndexPath, reports: reportIndexPath } = allReportsFromArray;
  const { following: followingIndexPath } = allFollowingFromArray;
  const { index: fleetIndex, path: fleetPath } = fleetIndexPath;
  const { index: userIndex, path: userPath } = userIndexPath;
  const { index: roleIndex, path: rolePath } = roleIndexPath;
  const { index: organizationIndex, path: organizationPath } = organizationIndexPath;
  const { index: systemIndex, path: systemPath } = systemIndexPath;
  const { index: visitIndex, path: visitPath } = visitIndexPath;
  const { index: baseIndex, path: basePath } = baseIndexPath;
  const { index: panelIndex, path: panelPath } = panelIndexPath;
  const { index: downloadsIndex, path: downloadsPath } = reportIndexPath;
  const { index: planningIndex, path: planningPath } = planningIndexPath;
  const { index: routesIndex, path: routesPath } = routeIndexPath;
  const { index: jobsIndex, path: jobsPath } = jobIndexPath;
  const { index: followingIndex, path: followingPath } = followingIndexPath;
  // create a key dynamic and link to
  const fleetKey = `configurations${fleetIndex ? '' : `/${fleetPath}`}`;
  const userKey = `configurations${userIndex ? '' : `/${userPath}`}`;
  const roleKey = `configurations${roleIndex ? '' : `/${rolePath}`}`;
  const organizationKey = `configurations${organizationIndex ? '' : `/${organizationPath}`}`;
  const systemKey = `configurations${systemIndex ? '' : `/${systemPath}`}`;
  const locationKey = `locations${visitIndex ? '' : `/${visitPath}`}`;
  const baseKey = `locations${baseIndex ? '' : `/${basePath}`}`;
  const panelKey = `reports${panelIndex ? '' : `/${panelPath}`}`;
  const downloadsKey = `reports${downloadsIndex ? '' : `/${downloadsPath}`}`;
  const planningKey = `planning${planningIndex ? '' : `/${planningPath}`}`;
  const routesKey = `planning${routesIndex ? '' : `/${routesPath}`}`;
  const jobsKey = `planning${jobsIndex ? '' : `/${jobsPath}`}`;
  const followingKey = `following${followingIndex ? '' : `/${followingPath}`}`;

  const canShowVisit = ability.can('read', 'visits');
  const canShowBase = ability.can('read', 'base');
  const scopeI18n = { scope: 'modules' };

  switch (module) {
    case 'planning':
      if (ability.can('read', 'planning')) {
        const ref = arrayRefs[0];
        addReference(`subPlanningRef`, ref);
        menus.push({
          key: planningKey,
          icon: <FontAwesomeIcon icon={['fas', 'file-pen']} />,
          label: (
            <Link to={`/${planningKey}`} ref={ref}>
              <span>{i18n.t('planning', scopeI18n)}</span>
            </Link>
          ),
        });
      }
      if (ability.can('read', 'routes')) {
        const ref = arrayRefs[1];
        addReference(`subRoutesRef`, ref);
        menus.push({
          key: routesKey,
          icon: <FontAwesomeIcon icon={['fas', 'list-ul']} />,
          label: (
            <Link to={`/${routesKey}`} ref={ref}>
              <span>{i18n.t('routesList', scopeI18n)}</span>
            </Link>
          ),
        });
      }
      if (ability.can('read', 'jobs')) {
        const ref = arrayRefs[2];
        addReference(`subJobsRef`, ref);
        menus.push({
          key: jobsKey,
          icon: <FontAwesomeIcon icon={['fas', 'route']} />,
          label: (
            <Link to={`/${jobsKey}`} ref={ref}>
              <span>{i18n.t('routeJobList', scopeI18n)}</span>
            </Link>
          ),
        });
      }
      nameModule = i18n.t('names.routes', scopeI18n);
      break;
    case 'following':
      if (ability.can('read', 'following')) {
        const ref = arrayRefs[0];
        addReference(`subFollowingRef`, ref);
        menus.push({
          key: followingKey,
          label: (
            <Link to={`/${followingKey}`} ref={ref}>
              <span>{i18n.t('index', scopeI18n)}</span>
            </Link>
          ),
        });
        nameModule = i18n.t('names.following', scopeI18n);
      }
      break;
    case 'configurations':
      if (ability.can('read', 'fleets')) {
        const ref = arrayRefs[0];
        addReference(`subFleetsRef`, ref);
        menus.push({
          key: fleetKey,
          icon: <FontAwesomeIcon icon={['fas', 'van-shuttle']} />,
          label: (
            <Link to={`/${fleetKey}`} ref={ref}>
              <span>{i18n.t('fleet', scopeI18n)}</span>
            </Link>
          ),
        });
      }
      if (ability.can('read', 'users')) {
        const ref = arrayRefs[1];
        addReference(`subUsersRef`, ref);
        menus.push({
          key: userKey,
          icon: <FontAwesomeIcon icon={['fas', 'users']} />,
          label: (
            <Link to={`/${userKey}`} ref={ref}>
              <span>{i18n.t('users', scopeI18n)}</span>
            </Link>
          ),
        });
      }
      if (ability.can('read', 'roles')) {
        const ref = arrayRefs[2];
        addReference(`subRolesRef`, ref);
        menus.push({
          key: roleKey,
          icon: <FontAwesomeIcon icon={['fas', 'clipboard-check']} />,
          label: (
            <Link to={`/${roleKey}`} ref={ref}>
              <span>{i18n.t('roles', scopeI18n)}</span>
            </Link>
          ),
        });
      }
      if (ability.can('read', 'organizations')) {
        const ref = arrayRefs[3];
        addReference(`subOrganizationssRef`, ref);
        menus.push({
          key: organizationKey,
          icon: <FontAwesomeIcon icon={['fas', 'clipboard-check']} />,
          label: (
            <Link to={`/${organizationKey}`} ref={ref}>
              <span>{i18n.t('organization', scopeI18n)}</span>
            </Link>
          ),
        });
      }
      if (ability.can('read', 'system')) {
        const ref = arrayRefs[4];
        addReference(`subSystemRef`, ref);
        menus.push({
          key: systemKey,
          icon: <FontAwesomeIcon icon={['fas', 'wrench']} />,
          label: (
            <Link to={`/${systemKey}`} ref={ref}>
              <span>{i18n.t('system', scopeI18n)}</span>
            </Link>
          ),
        });
      }
      nameModule = i18n.t('names.settings', scopeI18n);
      break;
    case 'locations':
      if (canShowVisit) {
        const ref = arrayRefs[0];
        addReference(`subVisitsRef`, ref);
        menus.push({
          key: locationKey,
          icon: <FontAwesomeIcon icon={['fas', 'location-pin']} />,
          label: (
            <Link to={`/${locationKey}`} ref={ref}>
              <span>{i18n.t('visit', scopeI18n)}</span>
            </Link>
          ),
        });
      }
      if (canShowBase) {
        const ref = arrayRefs[1];
        addReference(`subBaseRef`, ref);
        menus.push({
          key: baseKey,
          icon: <FontAwesomeIcon icon={['fas', 'house-chimney']} />,
          label: (
            <Link to={`/${baseKey}`} ref={ref}>
              <span>{i18n.t('depot', scopeI18n)}</span>
            </Link>
          ),
        });
      }
      if (canShowVisit || canShowBase) {
        nameModule = i18n.t('names.locations', scopeI18n);
      }
      break;
    case 'reports':
      if (ability.can('read', 'panels')) {
        const ref = arrayRefs[0];
        addReference(`subPanelsRef`, ref);
        menus.push({
          key: panelKey,
          icon: <FontAwesomeIcon icon={['fas', 'chart-pie']} />,
          label: (
            <Link to={`/${panelKey}`} ref={ref}>
              <span>{i18n.t('panels', scopeI18n)}</span>
            </Link>
          ),
        });
      }
      if (ability.can('read', 'reports')) {
        const ref = arrayRefs[1];
        addReference(`subReportsRef`, ref);
        menus.push({
          key: downloadsKey,
          icon: <FontAwesomeIcon icon={['fas', 'file-lines']} />,
          label: (
            <Link to={`/${downloadsKey}`} ref={ref}>
              <span>{i18n.t('report', scopeI18n)}</span>
            </Link>
          ),
        });
      }
      nameModule = i18n.t('names.reports', scopeI18n);
      break;
    case 'documents':
      menus = [];
      nameModule = i18n.t('names.documents', scopeI18n);
      break;
    default:
      menus = [];
      nameModule = '--';
      break;
  }
  return { menuItems: menus, nameModule };
};

function NavBar(props) {
  const { setCollapsed, collapsed } = props;
  const { pathname } = useLocation();
  const module = pathname.substring(1).split('/')[0];
  const isRoutes = module === 'planning';
  const ability = useContext(CaslContext);
  const { addReference } = useContext(TourContext);
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const showExperimentalDevelop = process.env.REACT_APP_SHOW_EXPERIMENTAL_DEVELOP;

  // define list of array for each subnav
  const arrayRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const { menuItems, nameModule } = getInfoByModule(module, ability, arrayRefs, addReference, i18n);

  return (
    <header className="container">
      <nav className="navbar">
        <Col md={0} xs={12}>
          <Button
            onClick={() => setCollapsed(!collapsed)}
            title={i18n.t('commons.showMenu')}
            style={{ marginLeft: '.5rem' }}
          >
            <FontAwesomeIcon icon={['fas', 'bars']} />
          </Button>
          <img className="logo-mobile" src={TOLogo} alt="logo-the-optimal" />
        </Col>
        <Col xs={0} md={8}>
          <Space>
            <Link to={`/${module}`}>
              <div className="view-name">
                <h2>{nameModule}</h2>
              </div>
            </Link>
            <div className="vertical-line" />
            {isRoutes && (
              <div className="button-view">
                <NewRouteModal />
              </div>
            )}
          </Space>
        </Col>
        <Space style={{ justifyContent: 'flex-end', marginLeft: 'auto' }} size="middle">
          <SearchContainer />
          {`${showExperimentalDevelop}` === 'true' && <LanguageSelector />}
          <TourButton />
          <DocumentStatus />
          <Notifications />
          <ProfileContainer />
          <OrganizationLogo />
        </Space>
      </nav>
      <Col xs={24} md={0} className="name-module-responsive">
        {nameModule}
      </Col>
      {menuItems.length > 0 && <SubNavBar menuItems={menuItems} />}
    </header>
  );
}

NavBar.defaultProps = {
  collapsed: true,
  setCollapsed: () => {},
};
NavBar.propTypes = {
  collapsed: PropTypes.bool,
  setCollapsed: PropTypes.func,
};
export default NavBar;
