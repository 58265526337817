import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Layout, Menu, Space } from 'antd';
import logoTOPlanners from 'assets/img/logo-TO-planners.svg';
import LocaleContext from 'components/locale/LocaleContext';
import { selectCurrentUser, selectUserActions } from 'features/users/userSlice';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { haveAtLeastOneSubModule } from 'services/repeated-functions';
import SidebarMobileButton from './Button';
import './style.css';

const { Sider } = Layout;
function SidebarMobile(props) {
  const { collapsed, setCollapsed } = props;
  const { pathname } = useLocation();
  const module = pathname.substring(1).split('/')[0];
  const userActions = useSelector(selectUserActions);
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'sidebar' };
  const items = [];

  if (haveAtLeastOneSubModule(userActions, 'planning')) {
    items.push(
      SidebarMobileButton({
        icon: 'fa-road',
        linkTo: '/planning',
        text: i18n.t('routes', scopeI18n),
        moduleName: 'planning',
        isSelected: module === 'planning',
      })
    );
  }
  if (haveAtLeastOneSubModule(userActions, 'following')) {
    items.push(
      SidebarMobileButton({
        icon: 'fa-crosshairs',
        linkTo: '/following',
        text: i18n.t('following', scopeI18n),
        moduleName: 'following',
        isSelected: module === 'following',
      })
    );
  }
  if (haveAtLeastOneSubModule(userActions, 'following')) {
    items.push(
      SidebarMobileButton({
        icon: 'fa-map-marked',
        linkTo: '/locations',
        text: i18n.t('locations', scopeI18n),
        moduleName: 'locations',
        isSelected: module === 'locations',
      })
    );
  }
  if (haveAtLeastOneSubModule(userActions, 'reports')) {
    items.push(
      SidebarMobileButton({
        icon: 'fa-chart-column',
        linkTo: '/reports',
        text: i18n.t('reports', scopeI18n),
        moduleName: 'reports',
        isSelected: module === 'reports',
      })
    );
  }
  items.push({ type: 'divider' });
  items.push(
    SidebarMobileButton({
      icon: 'fa-file-arrow-down',
      linkTo: '/documents',
      text: i18n.t('documents', scopeI18n),
      moduleName: 'documents',
      isSelected: module === 'documents',
    })
  );
  items.push({ type: 'divider' });
  if (haveAtLeastOneSubModule(userActions, 'configurations')) {
    items.push(
      SidebarMobileButton({
        icon: 'fa-cog',
        linkTo: '/configurations',
        text: i18n.t('settings', scopeI18n),
        moduleName: 'configurations',
        isSelected: module === 'configurations',
      })
    );
  }
  if (haveAtLeastOneSubModule(userActions, 'support')) {
    items.push(
      SidebarMobileButton({
        icon: 'fa-headset',
        linkTo: '/support',
        text: i18n.t('support', scopeI18n),
        moduleName: 'support',
        isSelected: module === 'support',
      })
    );
  }
  items.push({ type: 'divider' });
  const { organizations, organization } = useSelector(selectCurrentUser);
  const enableRibbon = organizations.length > 1;
  const defaultLogo = logoTOPlanners;
  const imageLogo = (
    <img
      className="image-logo-mobile"
      src={organization?.logoUrl || defaultLogo}
      alt={i18n.t('commons.clientLogo', { name: organization?.name })}
    />
  );
  const textRibbon = <FontAwesomeIcon icon={['fa', 'rotate']} />;

  items.push({
    label: (
      <Link className="" to="/organizations" title={i18n.t('commons.goToOrganizations')}>
        <Space size="middle">
          {`${i18n.t('commons.organization')} :`}
          {enableRibbon ? (
            <Badge.Ribbon text={textRibbon} placement="start" color="magenta">
              {imageLogo}
            </Badge.Ribbon>
          ) : (
            imageLogo
          )}
        </Space>
      </Link>
    ),
  });

  const onClick = () => {
    setCollapsed(true);
  };
  return (
    <Sider
      className="sidebar mobile-sidebar"
      breakpoint="md"
      collapsedWidth="0"
      trigger={null}
      collapsible
      collapsed={collapsed}
      theme="light"
      width={250}
    >
      <Menu items={items} onClick={onClick} />
    </Sider>
  );
}

SidebarMobile.defaultProps = {
  collapsed: true,
  setCollapsed: () => {},
};

SidebarMobile.propTypes = {
  collapsed: PropTypes.bool,
  setCollapsed: PropTypes.func,
};

export default SidebarMobile;
