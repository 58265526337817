import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button, Drawer, List, Skeleton, theme } from 'antd';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { RoutePropTypes } from 'types';

const currentRestrictions = [
  { name: 'demand_delivery_priorities', icon: 'p' },
  { name: 'demand_vehicle_min_capacity', icon: 'truck-front' },
  { name: 'demand_vehicle_max_capacity', icon: 'truck-front' },
  { name: 'demand_delivery_min_route_cost', icon: 'clock' },
  { name: 'demand_delivery_max_route_cost', icon: 'clock' },
  { name: 'lunch_min_route_costs', icon: 'clock' },
  { name: 'lunch_max_route_costs', icon: 'clock' },
  { name: 'minimum_permissible_truck', icon: 'truck-front' },
  { name: 'maximum_permissible_truck', icon: 'truck-front' },
];

function RestrictionsDrawer(props) {
  const { routes, restrictionsDrawerOpen, setRestrictionsDrawerOpen, isLoading } = props;
  const [itemRestrictions, setItemsRestrictions] = useState([]);
  const { token } = theme.useToken();
  const breachedRestrictionColor = token.colorError;

  useEffect(() => {
    if (routes.length > 0) {
      const localItemsRestrictions = [];
      routes.forEach(({ routeOrder, items }) => {
        items?.forEach(({ restrictions }) => {
          restrictions
            ?.filter((res) => res.breached)
            .forEach(({ label, name }) => {
              const currentRestriction = find(currentRestrictions, { name });
              localItemsRestrictions.push({
                icon: currentRestriction?.icon || 'question',
                title: `Ruta ${routeOrder}`,
                description: label,
              });
            });
        });
      });
      setItemsRestrictions(localItemsRestrictions);
    }
  }, [routes]);

  const onClose = (event) => {
    event?.stopPropagation();
    setRestrictionsDrawerOpen(false);
  };

  const footer = (
    <div className="footer-drawer-buttons">
      <Button type="default" onClick={() => onClose()}>
        Cerrar
      </Button>
    </div>
  );

  const body = () => {
    return (
      <List
        itemLayout="horizontal"
        dataSource={itemRestrictions}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <Avatar
                  size={42}
                  icon={
                    <FontAwesomeIcon icon={['fa', item?.icon]} color={breachedRestrictionColor} />
                  }
                  alt={item?.description}
                  style={{
                    backgroundColor: '#FFFFFF',
                    border: `1px solid ${breachedRestrictionColor}`,
                  }}
                />
              }
              title={item.title}
              description={item.description}
            />
          </List.Item>
        )}
      />
    );
  };
  if (isLoading) return <Skeleton paragraph={{ rows: 10 }} />;

  return (
    <Drawer
      title={`Restricciones (${itemRestrictions.length})`}
      onClose={() => onClose()}
      open={restrictionsDrawerOpen}
      footer={footer}
      destroyOnClose
      maskClosable={false}
      closable={false}
      forceRender
      zIndex="2000"
    >
      {body()}
    </Drawer>
  );
}

RestrictionsDrawer.propTypes = {
  routes: PropTypes.arrayOf(RoutePropTypes).isRequired,
  restrictionsDrawerOpen: PropTypes.bool.isRequired,
  setRestrictionsDrawerOpen: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default RestrictionsDrawer;
