import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, DatePicker, Form, Input, Select } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import TitleHelp from '../../TitleHelp';
import CustomAttributesPropTypes from '../customAttributes.propTypes';
import '../style.css';

const { Option } = Select;

function CustomAttributesFormField(props) {
  const { customAttribute, form } = props;
  const { id, name, description, dataType, options } = customAttribute;
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'form.customAttributes' };
  const label = <TitleHelp title={name} helpText={description} />;

  const defineComponent = () => {
    let elementToRender = i18n.t('unrecognizedType', { ...scopeI18n, dataType });
    switch (dataType) {
      case 'string':
        elementToRender = <Input key={`Ca${id}`} placeholder={name} form={form} />;
        break;
      case 'select':
        elementToRender = (
          <Select key={`Ca${id}`} placeholder={name} allowClear>
            {options.map((option) => {
              const { value: valueOption, label: labelOption, icon: iconOption } = option;
              return (
                <Option key={valueOption} value={valueOption}>
                  {iconOption ? (
                    <FontAwesomeIcon icon={iconOption} className="margin-right-icon" />
                  ) : undefined}
                  {labelOption}
                </Option>
              );
            })}
          </Select>
        );
        break;
      case 'date':
        elementToRender = <DatePicker key={`Ca${id}`} format="DD-MM-YYYY" />;
        break;
      case 'boolean':
        elementToRender = (
          <Checkbox key={`Ca${id}`} style={{ marginTop: '2rem' }}>
            {label}
          </Checkbox>
        );
        break;
      case 'number':
        elementToRender = <Input key={`Ca${id}`} placeholder={name} form={form} type="number" />;
        break;
      default:
        break;
    }
    return elementToRender;
  };

  return (
    <Form.Item
      key={`Fi${id}`}
      name={`ca[${id}]`}
      label={dataType === 'boolean' ? '' : label}
      valuePropName={dataType === 'boolean' ? 'checked' : 'value'}
    >
      {defineComponent()}
    </Form.Item>
  );
}

CustomAttributesFormField.propTypes = {
  customAttribute: CustomAttributesPropTypes,
  form: PropTypes.shape({
    getFieldError: PropTypes.func,
    getFieldInstance: PropTypes.func,
    getFieldValue: PropTypes.func,
    getFieldWarning: PropTypes.func,
    getFieldsError: PropTypes.func,
    getFieldsValue: PropTypes.func,
    getInternalHooks: PropTypes.func,
    isFieldTouched: PropTypes.func,
    isFieldValidating: PropTypes.func,
    isFieldsTouched: PropTypes.func,
    isFieldsValidating: PropTypes.func,
    resetFields: PropTypes.func,
    scrollToField: PropTypes.func,
    setFields: PropTypes.func,
    setFieldsValue: PropTypes.func,
    submit: PropTypes.func,
    validateFields: PropTypes.func,
  }),
};

CustomAttributesFormField.defaultProps = {
  customAttribute: {},
  form: {},
};

export default CustomAttributesFormField;
