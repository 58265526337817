import { useState } from 'react';
import PropTypes from 'prop-types';
import { App, Button, Col, Input, Row, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserPropTypes } from 'components/RoutesDemand/Assigment/assigment.propTypes';

function RouteEditFilter(props) {
  const { routes, setRoutes } = props;
  const [butonLoading, setButtonLoading] = useState(false);
  const { message } = App.useApp();

  const onSearch = (value) => {
    if (value === '') {
      setRoutes(routes);
      return;
    }
    const filteredRoutesIds = routes.reduce((a, o) => {
      const filtered = o?.items.filter(({ name }) =>
        name?.toLowerCase()?.includes(value.toLowerCase())
      );
      if (
        filtered.length ||
        o?.vehicle?.plate?.toLowerCase()?.includes(value.toLowerCase()) ||
        o?.driver?.fullName?.toLowerCase()?.includes(value.toLowerCase())
      ) {
        a.push({ ...o, items: filtered });
      }
      return a;
    }, []);
    const filteredRoutes = routes.filter(({ id }) =>
      filteredRoutesIds.map(({ id: routeId }) => routeId).includes(id)
    );
    if (filteredRoutes.length > 0) {
      setRoutes(filteredRoutes);
    } else {
      message.info('No se encontraron resultados para la búsqueda');
      setRoutes(routes);
    }
  };

  const onRecalculate = () => {
    setButtonLoading(true);
    setTimeout(() => {
      setButtonLoading(false);
    }, 2000);
  };
  return (
    <Row justify="end">
      <Col>
        <Space.Compact
          style={{
            marginBottom: '15px',
          }}
        >
          <Row gutter={8}>
            <Col>
              <Button
                icon={<FontAwesomeIcon icon={['fas', 'clock-rotate-left']} />}
                loading={butonLoading}
                onClick={() => onRecalculate()}
                title="Recalcular tiempos"
              />
            </Col>
            <Col>
              <Input.Search allowClear placeholder="Buscar" onSearch={onSearch} />
            </Col>
          </Row>
        </Space.Compact>
      </Col>
    </Row>
  );
}

RouteEditFilter.defaultProps = {
  routes: [],
  setRoutes: () => {},
};

RouteEditFilter.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      routingSetId: PropTypes.number,
      routeOrder: PropTypes.number,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
      ),
      driver: UserPropTypes,
      vehicle: PropTypes.shape({ plate: PropTypes.string }),
    })
  ),
  setRoutes: PropTypes.func,
};

export default RouteEditFilter;
