import PropTypes from 'prop-types';

export const ProfilesSelect = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })
);

export const LabelsSelect = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })
);

export const GenerarRoutingSettingPropTypes = PropTypes.shape({
  id: PropTypes.number,
  enableDeliveryCostPerLoadUnit: PropTypes.bool,
  enableDeliveryDemandCostAndRates: PropTypes.bool,
  enableFixedDeliveryStopCost: PropTypes.bool,
  enableMaxWaitingCost: PropTypes.bool,
  deliveryCostPerLoadUnit: PropTypes.number,
  deliveryDemandCostAndRates: PropTypes.number,
  fixedDeliveryStopCost: PropTypes.number,
  maxWaitingCost: PropTypes.number,
  minVehicles: PropTypes.number,
  profile: PropTypes.string,
  maxSendForWork: PropTypes.number,
});
