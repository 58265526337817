import PropTypes from 'prop-types';
import { useState } from 'react';
import { useMap } from 'react-leaflet';
import { cleanMapPoints, ColorByOrder } from 'services/repeated-functions';
import FontAwesomeMarker from './FontAwesomeMarker';
import { pointShapePropTypes } from './LeafletMap.propType';
import LeafletMarker from './LeafletMarker';
import LeafletPolyline from './LeafletPolyline';
import TrackingMarker from './TrackingMarker';

function LeafletMarkers(props) {
  const {
    points,
    showRoutes,
    typeMarkers,
    markerTriggerCallback,
    defaultCoords,
    markerType,
    setSelectedPoint,
  } = props;
  const mapPoints = cleanMapPoints(points);
  const [applyBounds, setApplyBounds] = useState(true);
  const map = useMap();
  const allPoints = [];
  const mapElements = [];
  const listColors = ['#FF4085', '#42A1FF', '#00B300', '#E6AC00', '#AC00E6', '#00CCCC'];
  const inactiveColor = '#808080';

  // if map has moved, the fitBounds is disabled
  map.on('dragend', () => {
    setApplyBounds(false);
  });

  // data
  if (typeMarkers === 'tracking') {
    // use points as array of objects
    mapPoints?.forEach((point) => {
      const { key, lat, lng } = point;
      allPoints.push([lat, lng]);
      const marker = (
        <TrackingMarker key={key} lat={lat} lng={lng} index={key} drawablesRows={point} />
      );
      mapElements.push(marker);
    });
  } else if (typeMarkers === 'routeDetail') {
    // used in route-detail/:id
    const routePoints = [];
    mapPoints?.forEach((point) => {
      const { key, lat, lng, type, order, color } = point;
      const coords = [lat, lng];
      allPoints.push(coords);
      routePoints.push(coords);
      let marker;
      if (type === 'vehicle') {
        marker = <TrackingMarker key={key} lat={lat} lng={lng} index={key} drawablesRows={point} />;
      } else {
        marker = (
          <LeafletMarker
            key={key}
            index={key}
            coords={coords}
            type={type}
            itemPosition={order}
            color={color}
            markerTriggerCallback={markerTriggerCallback}
          />
        );
      }
      mapElements.push(marker);
    });
    const plKey = `pl-route`;
    mapElements.push(
      <LeafletPolyline
        key={plKey}
        routeId={0}
        coords={routePoints}
        showPolygon={false}
        color="#999999"
      />
    );
  } else if (typeMarkers === 'draggable') {
    const routePoints = [];
    mapPoints?.forEach((point) => {
      const { key, lat, lng, type, draggable, onDrag } = point;
      const color = draggable ? '#FF4085' : '#808080';
      const coords = [lat, lng];
      allPoints.push(coords);
      routePoints.push(coords);
      const marker = (
        <LeafletMarker
          key={key}
          index={key}
          coords={coords}
          type={type}
          color={color}
          markerTriggerCallback={markerTriggerCallback}
          onDrag={onDrag}
          draggable={draggable}
          drawablesRows={point}
        />
      );
      mapElements.push(marker);
    });
  } else if (typeMarkers === 'following') {
    // use points as array of objects
    mapPoints?.forEach((point) => {
      const { key, lat, lng, type, order, color } = point;
      allPoints.push([lat, lng]);
      const coords = [lat, lng];
      let marker;
      if (type === 'vehicle') {
        marker = <TrackingMarker key={key} lat={lat} lng={lng} index={key} drawablesRows={point} />;
      } else {
        marker = (
          <LeafletMarker
            key={key}
            index={key}
            coords={coords}
            type={type}
            itemPosition={order}
            color={color}
          />
        );
      }
      mapElements.push(marker);
    });
  } else if (typeMarkers === 'eventReport') {
    const routePoints = [];

    // use points as array of objects
    mapPoints?.forEach((point) => {
      const { key, lat, lng, type, color, title } = point;
      allPoints.push([lat, lng]);
      const coords = [lat, lng];
      const marker = (
        <FontAwesomeMarker
          key={key}
          index={key}
          coords={coords}
          type={type}
          color={color}
          title={title}
        />
      );
      routePoints.push(coords);
      mapElements.push(marker);
    });
    // ToDo: check if is required
    const plKey = `pl-route`;
    mapElements.push(
      <LeafletPolyline
        key={plKey}
        routeId={0}
        coords={routePoints}
        showPolygon={false}
        color="#999999"
      />
    );
  } else {
    // ToDo: fix how manage points this must be an array from objects, logic be applied in each case
    points?.forEach((routeGroup, indexRoute) => {
      let color = listColors[indexRoute];
      const routePoints = [];
      routeGroup?.forEach((latLng, itemPosition) => {
        if (latLng.active !== undefined) {
          color = latLng.active === true ? listColors[indexRoute] : inactiveColor;
        }
        if (latLng.routeOrder) {
          color = ColorByOrder(latLng.routeOrder);
        }
        if (latLng?.lat && latLng.lng) {
          const coords = [latLng.lat, latLng.lng];
          allPoints.push(coords);
          routePoints.push(coords);
          const marker = (
            <LeafletMarker
              key={latLng.key}
              index={latLng.key}
              coords={coords}
              type={latLng.type}
              color={color}
              itemPosition={showRoutes ? itemPosition : itemPosition + 1}
              drawablesRows={latLng}
              markerType={markerType}
              setSelectedPoint={setSelectedPoint}
            />
          );
          mapElements.push(marker);
        }
      });
      if (showRoutes) {
        const plKey = `pl${indexRoute}`;
        mapElements.push(
          <LeafletPolyline key={plKey} routeId={indexRoute} coords={routePoints} color={color} />
        );
      }
    });
  }

  // fit map to bounds
  if (applyBounds) {
    map.fitBounds(allPoints.length > 0 ? allPoints : [defaultCoords], {
      maxZoom: map?.getZoom(),
    });
  }

  return mapElements;
}

LeafletMarkers.defaultProps = {
  points: [],
  showRoutes: false,
  typeMarkers: 'routes',
  markerTriggerCallback: undefined,
  defaultCoords: [],
  setSelectedPoint: () => {},
};

LeafletMarkers.propTypes = {
  points: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.arrayOf(pointShapePropTypes), pointShapePropTypes])
  ),
  showRoutes: PropTypes.bool,
  typeMarkers: PropTypes.string,
  markerTriggerCallback: PropTypes.func,
  defaultCoords: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  markerType: PropTypes.string,
  setSelectedPoint: PropTypes.func,
};

export default LeafletMarkers;
