import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { App, Col, Drawer, Empty, Flex, Form, Spin, Typography } from 'antd';
import FooterDrawer from 'components/common/FooterDrawer';
import LeafletMap from 'components/common/Map/LeafletMap';
import LocationEditForm from 'components/common/Map/LocationEditForm';
import ModalConfirm from 'components/common/ModalConfirm';
import TableFilters from 'components/common/TableFilter';
import LocaleContext from 'components/locale/LocaleContext';
import {
  useDeleteOrRestoreLocationMutation,
  useGetCategoriesVisitQuery,
  useGetLocationsQuery,
  useGetStopsSuggestedMutation,
} from 'features/locations/locationsApiSlice';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { getTitleToDrawer } from 'services/repeated-functions';

const { Text } = Typography;

function VisitLocationsMap(props) {
  const { setTriggerClean, triggerClean } = props;
  const [mapPoints, setMapPoints] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedPoint, setSelectedPoint] = useState();
  const [showEditForm, setShowEditForm] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'locations' };
  const entityName = i18n.t('visit', scopeI18n);
  const { data: categoriesData, isLoading: isLoadingCategories } = useGetCategoriesVisitQuery();
  const [getStopsSuggestedMutation, { isLoading: isLoadingMutation }] =
    useGetStopsSuggestedMutation();
  const { data: locationsData, isLoading: isLoadingLocationData } = useGetLocationsQuery(
    '?type=STOP&paginated=false',
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [deleteOrRestoreLocation] = useDeleteOrRestoreLocationMutation();
  const isLoading = isLoadingMutation || isLoadingLocationData || isLoadingCategories;

  useEffect(() => {
    if (selectedPoint) {
      const { action } = selectedPoint;
      if (action === 'edit') {
        setShowEditForm(true);
      } else {
        setShowDeleteModal(true);
      }
    }
  }, [selectedPoint]);

  useEffect(() => {
    if (!isLoadingCategories) {
      const allCategories = categoriesData.map((category) => ({
        label: category,
        value: category,
      }));
      setCategories(allCategories);
    }
  }, [categoriesData, isLoadingCategories]);

  const processMapPoint = (data) => {
    const points = data.map((mP) => ({
      key: mP.id,
      lat: mP.latitude,
      lng: mP.longitude,
      Cod: mP.nid,
      Nombre: mP.name,
      address: mP?.addresses,
      deletedAt: mP?.deletedAt,
      usedRows: ['Cod', 'Nombre'],
    }));
    setMapPoints(points);
  };

  useEffect(() => {
    const getPoints = async () => {
      processMapPoint(locationsData?.data || []);
    };
    if (locationsData) {
      getPoints();
    }
  }, [locationsData, isLoadingLocationData]);

  const renderMap = () =>
    mapPoints?.length > 0 ? (
      <LeafletMap
        dataSource={[mapPoints]}
        showAlertRoutes={false}
        markerType="circle"
        setSelectedPoint={setSelectedPoint}
        zoom={15}
      />
    ) : (
      <Empty
        image={<FontAwesomeIcon icon={['fas', 'folder-open']} color="#BFBFBF" />}
        imageStyle={{
          height: 60,
        }}
        description={<span>{i18n.t('alerts.zeroEntries', scopeI18n)}</span>}
      />
    );

  const renderLoading = () => <Spin size="large" />;

  const fieldsFilter = [
    {
      name: 'nid',
      label: i18n.t('code', scopeI18n),
      type: 'text',
    },
    {
      name: 'name',
      label: i18n.t('name', scopeI18n),
      type: 'text',
    },
    {
      name: 'address',
      label: i18n.t('city', scopeI18n),
      type: 'location_cascade',
    },
    {
      name: 'street',
      label: i18n.t('street', scopeI18n),
      type: 'text',
    },
    {
      name: 'updatedAt',
      label: i18n.t('date', scopeI18n),
      type: 'range',
    },
    {
      name: 'category',
      label: i18n.t('category', scopeI18n),
      type: 'select',
      options: categories,
    },
    {
      name: 'deletedAt',
      label: i18n.t('status', scopeI18n),
      type: 'select',
      options: [
        { value: 'true', label: i18n.t('commons.archived') },
        { value: 'false', label: i18n.t('commons.unarchived') },
      ],
    },
  ];

  const renderTableFilters = () => (
    <TableFilters
      setDataCallback={processMapPoint}
      fieldsFilter={fieldsFilter}
      showTitle={false}
      reduxFunc={getStopsSuggestedMutation}
      triggerClean={triggerClean}
    />
  );

  const renderEditForm = () => {
    const onClose = () => {
      setShowEditForm(false);
      setSelectedPoint();
    };
    const title = (
      <Text strong>{getTitleToDrawer('edit', entityName, selectedPoint?.Nombre, i18n)}</Text>
    );
    const footer = (
      <FooterDrawer
        onClose={onClose}
        typeDrawer="edit"
        form={form}
        entity={{ id: selectedPoint?.key }}
        buttonLoading={buttonLoading}
      />
    );
    return (
      <Drawer
        title={title}
        footer={footer}
        width="70%"
        onClose={onClose}
        open={showEditForm}
        destroyOnClose
        maskClosable={false}
        closable={false}
      >
        <LocationEditForm
          locationId={selectedPoint?.key}
          form={form}
          onClose={onClose}
          setButtonLoading={setButtonLoading}
          showEditForm={showEditForm}
          setTriggerClean={setTriggerClean}
        />
      </Drawer>
    );
  };

  const modalStatus = selectedPoint?.deletedAt ? 'warning' : 'error';
  const okText = selectedPoint?.deletedAt ? i18n.t('commons.unarchive') : i18n.t('commons.archive');
  const titleModal = i18n.t(selectedPoint?.deletedAt ? 'sureUnarchive' : 'sureArchive', {
    scope: 'commons',
    element: i18n.t('locations.visit'),
  });
  const colorButtonProps = { danger: selectedPoint?.deletedAt === null };
  const onCancelAction = (event) => {
    event.stopPropagation();
    setShowDeleteModal(false);
  };
  const onConfirmAction = async (event) => {
    event.stopPropagation();
    setTriggerClean(true);
    await deleteOrRestoreLocation(selectedPoint?.key)
      .unwrap()
      .then(() => {
        const messageSuccess = i18n.t('commons.entityArchivedUnarchivedF', {
          entity: i18n.t('location', scopeI18n),
          status: i18n.t('archivedFem', {
            scope: 'commons',
          }),
        });
        setTriggerClean(false);
        message.success(messageSuccess);
      })
      .catch((rejected) => {
        console.error(rejected);
        const errorMessage = i18n.t('cannotArchiveElement', {
          scope: 'commons',
          element: i18n.t('location', scopeI18n),
        });
        message.error(errorMessage);
      });
    setShowDeleteModal(false);
  };
  const renderModalConfirm = () => (
    <ModalConfirm
      onOk={onConfirmAction}
      onCancel={onCancelAction}
      title={titleModal}
      isModalVisible={showDeleteModal}
      okButtonProps={colorButtonProps}
      okText={okText}
      status={modalStatus}
    />
  );

  return (
    <>
      {renderTableFilters()}
      {!isLoading && <Col style={{ minHeight: '50vh', height: 400 }}>{renderMap()}</Col>}
      <Flex align="center" justify="center">
        {isLoading && renderLoading()}
      </Flex>
      {selectedPoint && renderEditForm()}
      {renderModalConfirm()}
    </>
  );
}

VisitLocationsMap.defaultProps = {
  setTriggerClean: () => {},
  triggerClean: false,
};

VisitLocationsMap.propTypes = {
  setTriggerClean: PropTypes.func,
  triggerClean: PropTypes.bool,
};

export default VisitLocationsMap;
