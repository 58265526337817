import { AutoComplete, Form } from 'antd';
import TitleHelp from 'components/common/TitleHelp';
import { useGetLocationsByFiltersStopMutation } from 'features/locations/locationsApiSlice';
import { useContext, useState } from 'react';
import PointsContext from './context/PointsContext';

function AutocompleteLocation() {
  const form = Form.useFormInstance();
  const [getLocationsByFilters] = useGetLocationsByFiltersStopMutation();
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState();
  const { mapPoints, setMapPoints } = useContext(PointsContext);

  const onSearch = async (searchText) => {
    if (searchText && searchText.length > 3) {
      const { data: dataLocation } = await getLocationsByFilters(`?nid=${searchText}`).unwrap();
      const locations = dataLocation?.map((resultItem) => {
        const locationLabel = `(${resultItem?.nid}) ${resultItem?.name}`;
        const { id: locationId, vip, name, latitude, longitude, addresses } = resultItem;
        return {
          key: resultItem?.id,
          value: resultItem?.nid,
          label: locationLabel,
          extraData: { locationId, vip, name, latitude, longitude, addresses },
        };
      });
      setOptions(locations);
    }
  };

  const onSelect = (_, selectedOption) => {
    const {
      value,
      extraData: { locationId, name, vip, latitude, longitude, addresses },
    } = selectedOption;
    if (selected && value === selected.key) {
      return;
    }
    if (selected && selected.key !== value) {
      // remove selected element from points
      setMapPoints((oldMapPoints) => oldMapPoints.filter((oP) => oP.key !== selected.key));
    }

    if (addresses) {
      const { country, province, city, number, street, line2, zipcode } = addresses;
      form.setFieldsValue({ addr: { country, province, city, number, street, line2, zipcode } });
    }

    form.setFieldsValue({
      locationId,
      name,
      vip,
      destination_latitude: latitude,
      destination_longitude: longitude,
    });
    // set pin on the map
    const obj = {
      key: value,
      lat: `${latitude}`,
      lng: `${longitude}`,
      Cod: value,
      Nombre: name,
      usedRows: ['Cod', 'Nombre'],
    };
    const notFounded = mapPoints.filter((item) => item.key === value).length === 0;
    if (notFounded) {
      setMapPoints((oldMapPoints) => [...oldMapPoints, obj]);
    }
    setSelected(obj);
  };

  const onClear = () => {
    setOptions([]);
    setSelected();
  };

  return (
    <Form.Item
      label={<TitleHelp title="Código cliente" helpText="Código único de identificación" />}
      name="nid"
      required
      rules={[{ required: true }]}
    >
      <AutoComplete
        options={options}
        onSelect={onSelect}
        onSearch={onSearch}
        onClear={onClear}
        placeholder="Escriba el código del cliente a buscar"
        allowClear
      />
    </Form.Item>
  );
}

export default AutocompleteLocation;
