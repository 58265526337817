import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Modal, Row, Space, Tooltip } from 'antd';
import { loadsFromOrgType } from 'types';

import './style.css';

function LoadsResume(props) {
  const { demands, loadsFromOrg } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const totalDemands = demands.length;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getTotalLoads = (demandsObj) => {
    const allLoads = {};
    const sumLoads = [];
    demandsObj.forEach((demand) => {
      const { loads } = demand;
      loads.forEach((load, idx) => {
        const loadValue = load?.load || 0;
        if (!allLoads[idx]) {
          allLoads[idx] = 0;
        }
        allLoads[idx] += parseInt(loadValue, 10);
      });
    });
    Object.keys(allLoads).forEach((key) => {
      const unit = loadsFromOrg.find((load) => load.key === parseInt(key, 10) + 1)?.name;
      if (unit) {
        sumLoads.push({
          key,
          value: allLoads[key],
          unit: unit || '--',
        });
      }
    });
    return sumLoads;
  };

  const resumeInfo = (demandsObj) => {
    const loads = getTotalLoads(demandsObj).map((loadObj) => {
      return `${loadObj.value} ${loadObj.unit}`;
    });
    return `Hay un total de ${totalDemands} demandas, ${loads.join(', ')}`;
  };

  return (
    <Space className="resume-info">
      {resumeInfo(demands)}
      <Tooltip title="Más información" onClick={showModal} className="tooltip-information">
        <FontAwesomeIcon icon={['fas', 'circle-info']} color="#999999" />
      </Tooltip>
      <Modal
        title="Total"
        open={isModalVisible}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        cancelText="Cerrar"
        width={350}
        destroyOnClose
        centered
        okButtonProps={{ style: { display: 'none' } }}
      >
        <Row>
          <Col span={12}>Demandas (D)</Col>
          <Col span={6} offset={6}>
            {totalDemands}
          </Col>
        </Row>
        {getTotalLoads(demands).map((loadObj) => {
          return (
            <Row key={loadObj.key}>
              <Col span={12}>{loadObj.unit}</Col>
              <Col span={6} offset={6}>
                {loadObj.value}
              </Col>
            </Row>
          );
        })}
      </Modal>
    </Space>
  );
}

LoadsResume.propTypes = {
  demands: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  loadsFromOrg: loadsFromOrgType.isRequired,
};

export default LoadsResume;
