import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  App,
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  List,
  Popover,
  Row,
  Switch,
  Typography,
} from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import { useProcessFileImporterMutation } from 'features/documents/fileProcessingApiSlice';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import ButtonDownload from '../ButtonDownload';
import { getFileForImporter, getTitleForImporter } from './drawer-functions';
import { LoadUnitsPropTypes, SettingsPropTypes } from './drawerImporter.propTypes';
import ImporterUpload from './UploadImporter';

import './style.css';

function DrawerImporter(props) {
  const { open, typeUpload, reduxFunc, setVisible, useGeoreference, settingsFields, loadsUnit } =
    props;
  const [isChecked, setIsChecked] = useState(false);
  const [errorResponse, setErrorResponse] = useState({});
  const [fileList, setFileList] = useState([]);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const { message } = App.useApp();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'form.demandsImport' };
  const [form] = Form.useForm();

  const buttonIsDisabled = fileList.length === 0 || Object.keys(errorResponse).length > 0;

  const [processFileImporter] = useProcessFileImporterMutation();

  const handleOnChangeSwitch = () => {
    setIsChecked(!isChecked);
  };
  const closeDrawer = () => {
    setFileList([]);
    setIsChecked(false);
    setVisible(false);
  };

  const footer = (
    <div className="footer-drawer-buttons">
      <Button onClick={closeDrawer}>{i18n.t('footerDrawer.cancel')}</Button>
      <Button
        id="saveButton"
        type="primary"
        style={{ marginLeft: '2rem' }}
        disabled={buttonIsDisabled}
        onClick={() => form.submit()} // send files and switch georeference
        loading={buttonIsLoading}
      >
        {i18n.t('footerDrawer.loadAndProcess')}
      </Button>
    </div>
  );

  const title = getTitleForImporter(typeUpload, i18n);
  const items = [
    {
      label: i18n.t('so.windows'),
      key: 'xlsx',
    },
    {
      label: i18n.t('so.others'),
      key: 'csv',
    },
  ];

  const downloadFile = async (event) => {
    const link = document.createElement('a');
    const { file, download } = getFileForImporter(event, loadsUnit, typeUpload);
    if (file && download) {
      link.href = file;
      link.download = download;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.log(
        `No file found for fileType: ${event.key} load: ${loadsUnit.length} typeLoad: ${typeUpload}`
      );
      message.error(i18n.t('errors.notFound', scopeI18n));
    }
  };
  const listHelps = ['onlyCSVWithSeparator', 'caseForExcel', 'maxSize', 'maxRows'];
  const titleHelp = (
    <div className="title-help-importer">
      <FontAwesomeIcon icon="fa-solid fa-circle-question" />
      {i18n.t('help.needHelpWithUpload', scopeI18n)}
    </div>
  );
  // ToDo: make dynamic by type
  const dataHelp = [
    i18n.t('help.howImportLocationsOrDemands', scopeI18n),
    i18n.t('help.colsFormatToPreventErrors', scopeI18n),
  ];

  const generalSettings = settingsFields
    .find(({ type }) => type === 'GENERALROUTING')
    ?.keyValue?.reduce((summ, setting) => {
      const [name, value] = Object.entries(setting)[0];
      return {
        ...summ,
        [name]: value,
      };
    }, {});

  const maxRows = generalSettings?.maxSendForWork ? generalSettings.maxSendForWork : 500;

  const typeForLocation = ['STOP', 'STOP-edit', 'DEPOT', 'DEPOT-edit'].includes(typeUpload)
    ? { type: typeUpload.split('-')[0] }
    : {};

  const onFinish = async (values) => {
    setButtonIsLoading(true);
    const { file, georeference, typeUpload: typeUploadForm } = values;
    const body = {
      ...values,
      ...(georeference !== undefined && { georeference }), // for false value send anyway
      typeUpload: typeUploadForm,
    };
    await processFileImporter({ file, body })
      .unwrap()
      .then(() => {
        setButtonIsLoading(false);
        closeDrawer();
        message.open({
          key: 'loading-msg',
          type: 'loading',
          content: i18n.t('alerts.processingRequest', scopeI18n),
          duration: 2,
        });
      })
      .catch(() => {
        message.error(i18n.t('alerts.somethingWentWrong', scopeI18n));
        setButtonIsLoading(false);
      });
  };

  const onFinishFailed = () => {
    console.log('finish failed');
  };

  const initialValues = {
    georeference: false,
    ...typeForLocation,
    typeUpload,
  };

  // reset form only if drawer is displayed
  useEffect(() => {
    if (open) {
      form.resetFields();
    }
  }, [form, open]);

  const showExperimentalDevelop = process.env.REACT_APP_SHOW_EXPERIMENTAL_DEVELOP;

  return (
    <Drawer
      title={title}
      width="655px"
      open={open}
      maskClosable={false}
      closable={false}
      footer={footer}
    >
      <Card bordered={false} className="card-instructions-importer">
        <Typography.Text underline className="title-instructions-importer">
          {i18n.t('instructions', scopeI18n)}
        </Typography.Text>
        <ul className="ul-instructions-importer">
          {listHelps.map((helpText, idx) => {
            const index = `li-${idx}`;
            return <li key={index}>{i18n.t(`help.${helpText}`, { ...scopeI18n, maxRows })}</li>;
          })}
        </ul>
        {/* ToDo: enable when support module was enabled */}
        {`${showExperimentalDevelop}` === 'true' && (
          <Popover
            placement="bottomLeft"
            overlayStyle={{ width: '383px' }}
            title={titleHelp}
            content={
              <List
                size="small"
                bordered
                dataSource={dataHelp}
                renderItem={(item) => (
                  <List.Item className="list-help-importer ">
                    <Button type="link">
                      {item}
                      <FontAwesomeIcon icon="fa-solid  fa-chevron-right" color="#6B7C8B" />
                    </Button>
                  </List.Item>
                )}
              />
            }
            arrow={false}
          >
            <Button className="btn-help-importer">
              <FontAwesomeIcon icon="fa-solid fa-circle-question" />
              {i18n.t('helpText', scopeI18n)}
            </Button>
          </Popover>
        )}
      </Card>
      <Form
        form={form}
        layout="vertical"
        validateMessages={{ required: i18n.t('fieldRequired', scopeI18n) }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={initialValues}
      >
        <Row style={{ paddingTop: '2rem' }}>
          {/* upload georeference */}
          {useGeoreference && (
            <Col span={18} flex="auto" style={{ paddingTop: '5px' }}>
              <Row>
                <Col span={4}>
                  <Form.Item
                    name="georeference"
                    style={{ marginBottom: 0 }}
                    valuePropName="checked"
                  >
                    <Switch
                      checked={isChecked}
                      onChange={handleOnChangeSwitch}
                      style={{ marginRight: '.5rem' }}
                    />
                  </Form.Item>
                </Col>
                <Col span={16} style={{ paddingTop: '.3rem' }}>
                  {i18n.t('georeferenceAllEntries', scopeI18n)}
                  <Popover
                    arrow={false}
                    placement="bottomLeft"
                    overlayStyle={{ width: '320px' }}
                    content={
                      <Row
                        gutter={[8, 8]}
                        style={{ paddingTop: '.5rem', paddingBottom: '.5rem', color: '#6B7C8B' }}
                      >
                        <Col span={4}>
                          <Switch disabled />
                        </Col>
                        <Col span={20} style={{ paddingLeft: 5 }}>
                          <b>{i18n.t('help.georeference.disabled', scopeI18n)}: </b>
                          {i18n.t('help.georeference.disabledDetail', scopeI18n)}
                        </Col>
                        <Col span={4}>
                          <Switch value disabled />
                        </Col>
                        <Col span={20} style={{ paddingLeft: 5 }}>
                          <b>{i18n.t('help.georeference.enabled', scopeI18n)}: </b>
                          {i18n.t('help.georeference.enabledDetail', scopeI18n)}
                        </Col>
                      </Row>
                    }
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle-info"
                      color="#6B7C8B"
                      style={{ marginLeft: '.5rem' }}
                    />
                  </Popover>
                </Col>
              </Row>
            </Col>
          )}
          <Col span={useGeoreference ? 6 : 24} flex="auto">
            <div className={useGeoreference ? 'float-right' : ''}>
              <ButtonDownload
                key="download-example-demand-button"
                size="medium"
                showIcon={false}
                onClickEvent={downloadFile}
                items={items}
                buttonName={i18n.t('exampleTemplate', scopeI18n)}
              />
            </div>
          </Col>
        </Row>
        <Row style={{ paddingTop: '2rem' }}>
          <Col span={24}>
            {Object.keys(typeForLocation).length > 0 && (
              <Form.Item name="type" hidden>
                <Input />
              </Form.Item>
            )}
            <Form.Item name="typeUpload" hidden>
              <Input />
            </Form.Item>
            {/* upload */}
            <ImporterUpload
              reduxFunc={reduxFunc}
              otherPayload={{
                ...typeForLocation,
              }}
              errorResponse={errorResponse}
              setErrorResponse={setErrorResponse}
              fileList={fileList}
              setFileList={setFileList}
            />
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}

DrawerImporter.defaultProps = {
  open: false,
  typeUpload: 'advanced',
  reduxFunc: () => {},
  setVisible: () => {},
  useGeoreference: false,
  settingsFields: [],
  loadsUnit: [],
};

DrawerImporter.propTypes = {
  open: PropTypes.bool,
  typeUpload: PropTypes.string,
  reduxFunc: PropTypes.func,
  setVisible: PropTypes.func,
  useGeoreference: PropTypes.bool,
  settingsFields: SettingsPropTypes,
  loadsUnit: LoadUnitsPropTypes,
};

export default DrawerImporter;
