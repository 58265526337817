import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, App, Button, Col, Dropdown, Empty, Form, Row, Skeleton } from 'antd';
import BreadcrumbComponent from 'components/common/Breadcrumb';
import ModalConfirm from 'components/common/ModalConfirm';
import PageHeader from 'components/common/PageHeader';
import TourContext from 'components/layout/TourComponent/TourContext';
import LocaleContext from 'components/locale/LocaleContext';
import { useGetOrganizationQuery } from 'features/organizations/organizationsApiSlice';
import { useSaveChangedRoutesMutation } from 'features/routes/routesApiSlice';
import { useGetRoutingSetQuery } from 'features/routingSets/routingSetsApiSlice';
import { useGetSettingsQuery } from 'features/settings/settingsApiSlice';
import { selectCurrentOrganization } from 'features/users/userSlice';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  checkPermissionForSubject,
  displayUnauthorizedNotification,
  getAllIndexFromArray,
} from 'services/repeated-functions';
import CaslContext from 'utils/caslContext';
import RoutesDemandMap from '../Map';
import NavRouteButtons from '../NavRouteButtons';
import RouteEdit from '../RouteEdit';
import RouteEditFilter from '../RouteEdit/Filter';
import RoutesDemandSynoptic from '../Synoptic';
import AddRestDrawer from './AddRestDrawer';
import RouteEditorDrawer from './Drawer';
import RestrictionsDrawer from './RestrictionsDrawer';
import useItems from './useItems';

const SELECT_ALL = -1;

function RoutesDemandEditor() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { id: currOrgId } = useSelector(selectCurrentOrganization);
  const { data: organization, isLoading: isLoadingOrg } = useGetOrganizationQuery(currOrgId);
  const loadsFromOrg = organization?.loadsUnit || [];
  const [showMap, setShowMap] = useState(true);
  const [selectedRoute, setSelectedRoute] = useState(SELECT_ALL);
  const ability = useContext(CaslContext);
  const [allRoutes, setAllRoutes] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [dataFormated, setDataFormated] = useState([]);
  const [routeStops, setRouteStops] = useState([]);
  const [routingSet, setRoutingSet] = useState();
  const [removeItemDisabled, setRemoveItemDisabled] = useState(true);
  const [checkedItems, setCheckedItems] = useState([]);
  const [routeEditForm] = Form.useForm();
  const [currentUpperValue, setCurrentUpperValue] = useState('normal');
  const [currentLowerValue, setCurrentLowerValue] = useState('normal');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [restDrawerOpen, setRestDrawerOpen] = useState(false);
  const [restrictionsDrawerOpen, setRestrictionsDrawerOpen] = useState(false);
  const [itemSelected, setItemSelected] = useState();
  const [removeItemsFromRoutes, removeItemsIsLoading, removeItemsErrors, setErrors] = useItems(
    checkedItems,
    allRoutes
  );
  const [isModalConfirmVisible, setIsModalConfirmVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRoutingSetUnchanged, setIsRoutingSetUnchanged] = useState(true);
  const [isSavingRoutingSet, setIsSavingRoutingSet] = useState(false);
  const [saveChangedRoutes] = useSaveChangedRoutesMutation();
  const { message, notification } = App.useApp();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'routes.editor' };

  const { addReference } = useContext(TourContext);
  const routeEditSynopticRef = useRef(null);
  addReference('routeEditSynopticRef', routeEditSynopticRef);
  const routeEditHeaderRef = useRef(null);
  addReference('routeEditHeaderRef', routeEditHeaderRef);

  // load settings for default action
  const { data: settingsFields, isLoading: isLoadingSetting } = useGetSettingsQuery(
    'DISPLAY,GENERALROUTING?format=false'
  );

  // object for accepted key-value for upper & lower container
  const upperValues = {
    expand: 85,
    normal: 65,
    hide: 0,
  };

  // values for table derive from upperValues
  const tableValues = {
    expand: 76,
    normal: 60,
    hide: 0,
  };

  // custom hooks
  const { data: routingSetData, isLoading } = useGetRoutingSetQuery(id, {
    skip: id === undefined,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    setRemoveItemDisabled(checkedItems.length === 0);
  }, [checkedItems, setRemoveItemDisabled]);

  useEffect(() => {
    if (routingSetData) {
      const { routes: routesFromRoutingSet } = routingSetData;
      const allUneditable = routesFromRoutingSet
        .map(({ editable }) => editable)
        .every((editable) => editable === false);
      setAllRoutes(routesFromRoutingSet);
      setRoutes(routesFromRoutingSet);
      setRoutingSet(routingSetData);
      if (allUneditable) {
        navigate(`/planning/routes/${id}/show`);
        notification.error({
          message: 'Error 422',
          description: 'No es posible editar ninguna ruta del conjunto de rutas',
          icon: (
            <FontAwesomeIcon
              icon={['fas', 'circle-exclamation']}
              style={{ color: 'rgb(227, 0, 1)' }}
            />
          ),
        });
      }
    }
  }, [routingSetData, isLoading, navigate, id, notification]);

  // build data for table, check if is used
  useEffect(() => {
    const buildData = (routesFetched) => {
      const dataBuilded = routesFetched.map((route, index) => {
        return {
          id: route.id,
          key: route.id,
          route: index + 1,
          action: route.action,
          routeOrder: route.routeOrder,
          driver: route.driver,
          assistant: route.assistant,
          vehicle: route.vehicle,
          editable: route.editable,
          status: route.status,
          items: route.items?.map((item) => {
            return {
              key: item.id,
              lat: item.latitude,
              lng: item.longitude,
              type: item.type,
              routeOrder: route.routeOrder,
              editable: item.editable,
            };
          }),
        };
      });
      return dataBuilded;
    };

    setDataFormated(buildData(routes));
  }, [routes]);
  useEffect(() => {
    const stopItems = dataFormated.map((route) => route.items);
    setRouteStops(stopItems);
  }, [dataFormated]);

  useEffect(() => {
    if (removeItemsErrors.length > 0) {
      message.error(removeItemsErrors.join(', '));
      setCheckedItems([]);
    }
  }, [message, removeItemsErrors]);

  const allRoutesFromArray = getAllIndexFromArray(['planning', 'routes', 'jobs'], ability);
  const { routes: routeIndexPath } = allRoutesFromArray;
  const { index: routesIndex, path: routesPath } = routeIndexPath;
  const routesKey = `planning${routesIndex ? '' : `/${routesPath}`}`;

  const breadcrumbItems = [
    {
      key: 'home',
      url: '/planning',
      label: i18n.t('sidebar.routes'),
    },
    {
      key: 'routes',
      url: `/${routesKey}`,
      label: i18n.t('modules.routesList'),
    },
    {
      key: 'routeDetail',
      url: `/planning/routes/${id}`,
      label: i18n.t('routingSets', scopeI18n),
    },
    {
      key: 'routeEdit',
      url: `/planning/routes/${id}/edit`,
      label: i18n.t('editor', scopeI18n),
    },
  ];

  const hasPermissionUpdate = checkPermissionForSubject(ability, 'update', 'routes');
  useEffect(() => {
    if (!hasPermissionUpdate) {
      navigate('/planning/unauthorized');
      displayUnauthorizedNotification(notification);
    }
  }, [hasPermissionUpdate, navigate, notification]);

  const handleOnClickNavRoute = useCallback((selected) => {
    setSelectedRoute(selected);
  }, []);

  const removeItems = async (event) => {
    event?.stopPropagation();
    setIsModalVisible(false);
    const reRoutes = await removeItemsFromRoutes();
    // in case total routes are changed, restart filter for selected route
    if (allRoutes.length !== reRoutes.length) {
      setSelectedRoute(SELECT_ALL);
    }
    setRoutes(reRoutes);
    // modify all universe of routes to apply filters
    setAllRoutes(reRoutes);
    setCheckedItems([]);
    setErrors([]);
    setIsRoutingSetUnchanged(false);
  };
  const showModal = (event) => {
    event.stopPropagation();
    setIsModalVisible(true);
  };
  const onCancelAction = (event) => {
    event.stopPropagation();
    setIsModalVisible(false);
  };
  const saveAllChanges = async (event) => {
    event.stopPropagation();
    setIsSavingRoutingSet(true);
    try {
      await saveChangedRoutes({
        routingSetId: id,
        body: {
          routes: allRoutes,
        },
      });
      message.success(i18n.t('alerts.routesUpdated', scopeI18n));
      setIsRoutingSetUnchanged(true);
    } catch (error) {
      console.error(error);
      message.error(i18n.t('errors.cannotUpdate', scopeI18n));
    }
    setIsSavingRoutingSet(false);
  };

  const removeItemsButton = (
    <Button
      key="btn-remove-items"
      type="primary"
      danger
      disabled={removeItemDisabled}
      onClick={(event) => showModal(event)}
      loading={removeItemsIsLoading}
      title={removeItemDisabled ? i18n.t('errors.selectRouteToDelete', scopeI18n) : ''}
    >
      {i18n.t('buttons.delete')}
    </Button>
  );

  const saveEditFormButton = (
    <Button
      key="btn-save-edit"
      type="primary"
      loading={removeItemsIsLoading || isSavingRoutingSet}
      disabled={routes.length === 0 || isRoutingSetUnchanged}
      onClick={saveAllChanges}
      title={isRoutingSetUnchanged ? i18n.t('errors.makeChangeInRouteToSave', scopeI18n) : ''}
    >
      {i18n.t('buttons.saveChanges')}
    </Button>
  );

  const onClickDropdown = ({ key }) => {
    if (key === 'addManualStop') {
      setDrawerOpen(true);
    } else if (key === 'addRest') {
      setRestDrawerOpen(true);
    }
  };

  const moreButton = (
    <Dropdown
      key="btn-more"
      menu={{
        items: [
          {
            key: 'addManualStop',
            label: i18n.t('addManualStop', scopeI18n),
          },
          {
            key: 'addRest',
            label: i18n.t('addRest', scopeI18n),
          },
        ],
        onClick: onClickDropdown,
      }}
      placement="bottom"
    >
      <Button
        key="btn-more-2"
        style={{
          width: '32px',
          padding: 0,
        }}
      >
        <FontAwesomeIcon icon="fa-solid fa-ellipsis" color="#999999" />
      </Button>
    </Dropdown>
  );

  const toggleMap = () => {
    if (showMap) {
      setShowMap(!showMap);
    } else {
      setShowMap(!showMap);
    }
  };

  const toggleMapButton = (
    <Button
      key="btn-toggleMap"
      onClick={toggleMap}
      style={{
        width: '20px',
        height: '32px',
        padding: 0,
      }}
    >
      <FontAwesomeIcon icon={['fas', showMap ? 'angle-right' : 'angle-left']} />
    </Button>
  );

  const routeEditFormOnFinish = async (values) => {
    console.log('routeEditFormOnFinish', values);
  };

  const onCancelActionConfirm = () => {
    setIsModalConfirmVisible(false);
  };

  const onClickBack = () => {
    if (isRoutingSetUnchanged) {
      navigate(`/planning/routes/${id}`);
    } else {
      setIsModalConfirmVisible(true);
    }
  };

  useEffect(() => {
    setDrawerOpen(itemSelected !== undefined);
  }, [itemSelected]);

  return (
    <>
      <ModalConfirm
        onOk={() => navigate(`/planning/routes/${id}`)}
        onCancel={onCancelActionConfirm}
        title={i18n.t('sureRevertChanges', scopeI18n)}
        isModalVisible={isModalConfirmVisible}
        okText={i18n.t('undo', scopeI18n)}
        status="warning"
        okButtonProps={{ danger: false }}
      />
      <ModalConfirm
        onOk={removeItems}
        onCancel={onCancelAction}
        title={i18n.t('sureDeleteStop', { ...scopeI18n, count: checkedItems.length })}
        isModalVisible={isModalVisible}
        okText={i18n.t('buttons.delete')}
        status="error"
      />
      <div className="demands-container">
        <Row
          gutter={16}
          className="upper-container"
          style={{
            height: `${upperValues[currentUpperValue]}%`,
            opacity: currentUpperValue === 'hide' ? 0 : 1,
          }}
        >
          <Col
            className="upper-container table-element"
            style={{
              width: showMap ? '60%' : '100%',
            }}
          >
            <BreadcrumbComponent breadcrumbItems={breadcrumbItems} />

            <Skeleton loading={isLoading} paragraph={{ rows: 12 }}>
              <PageHeader
                className="padding-page-header"
                onBack={onClickBack}
                title={routingSet?.name}
                extra={[removeItemsButton, saveEditFormButton, moreButton, toggleMapButton]}
              />
              {!isRoutingSetUnchanged && (
                <Alert
                  type="info"
                  message={i18n.t('alerts.rememberSave', scopeI18n)}
                  showIcon
                  style={{ marginBottom: '.5rem' }}
                />
              )}
              <Skeleton active paragraph={{ rows: 2 }} loading={allRoutes.length === 0}>
                <NavRouteButtons
                  size={allRoutes.length}
                  onClick={handleOnClickNavRoute}
                  selectedRoute={selectedRoute}
                />
              </Skeleton>
              <>
                <Skeleton active paragraph={{ rows: 2 }} loading={allRoutes.length === 0}>
                  <RouteEditFilter routes={routes} setRoutes={setRoutes} />
                </Skeleton>
                <div
                  style={{
                    height: `${tableValues[currentUpperValue]}%`,
                    overflow: 'auto',
                  }}
                >
                  <Form
                    form={routeEditForm}
                    onFinish={routeEditFormOnFinish}
                    name="routeEdit"
                    autoComplete="off"
                  >
                    {routes.map((route) => (
                      <RouteEdit
                        key={route.id}
                        route={route}
                        checkedItems={checkedItems}
                        setCheckedItems={setCheckedItems}
                        loadsFromOrg={loadsFromOrg}
                        setRoutes={setRoutes}
                        setAllRoutes={setAllRoutes}
                        allRoutes={allRoutes}
                        removeItemsIsLoading={removeItemsIsLoading || itemSelected !== undefined}
                        setIsRoutingSetUnchanged={setIsRoutingSetUnchanged}
                        isRoutingSetUnchanged={isRoutingSetUnchanged}
                        canEdit={route.editable}
                        routeEditHeaderRef={routeEditHeaderRef}
                        setSelectedRoute={setSelectedRoute}
                        setItemSelected={setItemSelected}
                      />
                    ))}
                    {routes.length === 0 && <Empty description={i18n.t('emptyList', scopeI18n)} />}
                  </Form>
                </div>
              </>
            </Skeleton>
          </Col>
          {showMap && currentLowerValue !== 'expand' && (
            <RoutesDemandMap
              routeStops={routeStops}
              allRoutes={allRoutes}
              routingSet={routingSet}
              isEditable={!isRoutingSetUnchanged}
              showMap={showMap}
              setRestrictionsDrawerOpen={setRestrictionsDrawerOpen}
            />
          )}
        </Row>
        <span ref={routeEditSynopticRef} style={{ width: '100%' }}>
          <Skeleton active paragraph={{ rows: 5 }} loading={allRoutes.length === 0}>
            <RoutesDemandSynoptic
              currentLowerValue={currentLowerValue}
              setCurrentLowerValue={setCurrentLowerValue}
              setCurrentUpperValue={setCurrentUpperValue}
              routes={routes}
              setRoutes={setRoutes}
              selectedRoute={selectedRoute}
              allRoutes={allRoutes}
              setRestrictionsDrawerOpen={setRestrictionsDrawerOpen}
            />
          </Skeleton>
        </span>
      </div>
      {!isLoading && !isLoadingOrg && !isLoadingSetting && (
        <>
          <RouteEditorDrawer
            organization={organization}
            settingsFields={settingsFields}
            setDrawerOpen={setDrawerOpen}
            drawerOpen={drawerOpen}
            setRoutes={setRoutes}
            setAllRoutes={setAllRoutes}
            allRoutes={allRoutes}
            setIsRoutingSetUnchanged={setIsRoutingSetUnchanged}
            itemSelected={itemSelected}
            setItemSelected={setItemSelected}
            setSelectedRoute={setSelectedRoute}
          />
          <RestrictionsDrawer
            routes={routes}
            restrictionsDrawerOpen={restrictionsDrawerOpen}
            setRestrictionsDrawerOpen={setRestrictionsDrawerOpen}
            isLoading={isLoading}
          />
          <AddRestDrawer
            setDrawerOpen={setRestDrawerOpen}
            drawerOpen={restDrawerOpen}
            routes={routes}
            setRoutes={setRoutes}
            setAllRoutes={setAllRoutes}
            allRoutes={allRoutes}
            setIsRoutingSetUnchanged={setIsRoutingSetUnchanged}
          />
        </>
      )}
    </>
  );
}

export default RoutesDemandEditor;
