import routingLogo from 'assets/img/sidebar-logo.svg';
import LocaleContext from 'components/locale/LocaleContext';
import { selectUserActions } from 'features/users/userSlice';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { haveAtLeastOneSubModule } from 'services/repeated-functions';
import SidebarButton from './Button';
import './style.css';

function Sidebar() {
  const { pathname } = useLocation();
  const module = pathname.substring(1).split('/')[0];
  const userActions = useSelector(selectUserActions);
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'sidebar' };

  return (
    <aside className="sidebar web-sidebar">
      <div className="sidebar-logo">
        <img className="logo" src={routingLogo} alt="logo-the-optimal" />
      </div>

      <div className="sidebar-icons">
        {haveAtLeastOneSubModule(userActions, 'planning') && (
          <SidebarButton
            isSelected={module === 'planning'}
            icon="fa-road"
            text={i18n.t('routes', scopeI18n)}
            linkTo="/planning"
            moduleName="planning"
          />
        )}
        {haveAtLeastOneSubModule(userActions, 'following') && (
          <SidebarButton
            isSelected={module === 'following'}
            icon="fa-crosshairs"
            text={i18n.t('following', scopeI18n)}
            linkTo="/following"
            moduleName="following"
          />
        )}
        {haveAtLeastOneSubModule(userActions, 'locations') && (
          <SidebarButton
            isSelected={module === 'locations'}
            icon="fa-map-marked"
            text={i18n.t('locations', scopeI18n)}
            linkTo="/locations"
            moduleName="locations"
          />
        )}
        {haveAtLeastOneSubModule(userActions, 'reports') && (
          <SidebarButton
            isSelected={module === 'reports'}
            icon="fa-chart-column"
            text={i18n.t('reports', scopeI18n)}
            linkTo="/reports"
            moduleName="reports"
          />
        )}
        <hr />
        <br />
        {haveAtLeastOneSubModule(userActions, 'configurations') && (
          <SidebarButton
            isSelected={module === 'configurations'}
            icon="fa-cog"
            text={i18n.t('settings', scopeI18n)}
            linkTo="/configurations"
            moduleName="configurations"
          />
        )}
        {haveAtLeastOneSubModule(userActions, 'support') && (
          <SidebarButton
            isSelected={module === 'support'}
            icon="fa-headset"
            text={i18n.t('support', scopeI18n)}
            linkTo="/support"
            moduleName="support"
          />
        )}
      </div>
    </aside>
  );
}

export default Sidebar;
