import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row, Skeleton } from 'antd';
import Breadcrumb from 'components/common/Breadcrumb';
import ModalConfirm from 'components/common/ModalConfirm';
import PageHeader from 'components/common/PageHeader';
import dayjs from 'dayjs';
import { useGetRoutingSetQuery } from 'features/routingSets/routingSetsApiSlice';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { checkPermissionForSubject, getAllIndexFromArray } from 'services/repeated-functions';
import CaslContext from 'utils/caslContext';
import RestrictionsDrawer from './Editor/RestrictionsDrawer';
import RoutesDemandMap from './Map';
import NavRouteButtons from './NavRouteButtons';
import RoutesDemandSynoptic from './Synoptic';
import TableContent from './TableContent';
import './style.css';

const SELECT_ALL = -1;

function RoutesDemand() {
  const { id } = useParams();
  const navigate = useNavigate();
  const ability = useContext(CaslContext);
  const [showMap, setShowMap] = useState(true);
  const [selectedRoute, setSelectedRoute] = useState(SELECT_ALL);
  const [allRoutes, setAllRoutes] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [dataFormated, setDataFormated] = useState([]);
  const [routeStops, setRouteStops] = useState([]);
  const [routingSet, setRoutingSet] = useState();
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [restrictionsDrawerOpen, setRestrictionsDrawerOpen] = useState(false);

  const [currentUpperValue, setCurrentUpperValue] = useState('normal');
  const [currentLowerValue, setCurrentLowerValue] = useState('normal');

  // object for accepted key-value for upper & lower container
  const upperValues = {
    expand: 85,
    normal: 59,
    hide: 0,
  };

  // values for table derive from upperValues
  const tableValues = {
    expand: 76,
    normal: 66,
    hide: 0,
  };

  // custom hooks
  const { data: routingSetData, isLoading } = useGetRoutingSetQuery(id, {
    skip: id === undefined,
    refetchOnMountOrArgChange: true,
  });

  // do this external to fix error
  const hasPermissionUpdate = checkPermissionForSubject(ability, 'update', 'routes');
  const hasPermissionDelete = checkPermissionForSubject(ability, 'delete', 'routes');
  const hasPermissionFollowing = checkPermissionForSubject(ability, 'read', 'following');

  const converterSecondsToHour = (seconds) => {
    const travelTimeInSec = Math.round(seconds);
    const initialTime = dayjs().endOf('month').startOf('day');
    const newTime = initialTime.add(Math.abs(travelTimeInSec), 'seconds');
    const stringFormat = travelTimeInSec > 86400 ? 'D[d] HH[h] mm[m]' : 'HH[h] mm[m]';
    return newTime.format(stringFormat);
  };

  useEffect(() => {
    if (routingSetData) {
      setAllRoutes(routingSetData.routes);
      setRoutes(routingSetData.routes);
      setRoutingSet(routingSetData);
    }
  }, [routingSetData, isLoading]);

  // build data for table, check if is used
  useEffect(() => {
    const buildData = (routesFetched) => {
      const dataBuilded = routesFetched.map((route, index) => {
        const { startsAt, endsAt, loads } = route;
        return {
          id: route.id,
          key: route.id,
          route: index + 1,
          startsAt,
          endsAt,
          loads,
          action: route.action,
          assignment: null,
          totalStops: route.totalStops,
          routeOrder: route.routeOrder,
          driver: route.driver,
          assistant: route.assistant,
          vehicle: route.vehicle,
          totalDistance: route.totalDistance,
          travelTime: converterSecondsToHour(route.travelTime),
          relaxedConstraints: route.relaxedConstraints,
          editable: route.editable,
          status: route.status,
          items: route.items?.map((item) => {
            return {
              key: item.id,
              lat: item.latitude,
              lng: item.longitude,
              type: item.type,
              routeOrder: route.routeOrder,
              editable: item.editable,
            };
          }),
        };
      });
      return dataBuilded;
    };

    setDataFormated(buildData(routes));
  }, [routes]);

  useEffect(() => {
    const stopItems = dataFormated.map((route) => route.items);
    setRouteStops(stopItems);
  }, [dataFormated]);

  const allRoutesFromArray = getAllIndexFromArray(['planning', 'routes', 'jobs'], ability);
  const { routes: routeIndexPath } = allRoutesFromArray;
  const { index: routesIndex, path: routesPath } = routeIndexPath;
  const routesKey = `planning${routesIndex ? '' : `/${routesPath}`}`;

  const breadcrumbItems = [
    {
      key: 'home',
      url: '/planning',
      label: 'Rutas',
    },
    {
      key: 'routes',
      url: `/${routesKey}`,
      label: 'Lista de rutas',
    },
    {
      key: 'routeDetail',
      url: `/planning/routes/${id}`,
      label: 'Conjunto de rutas',
    },
  ];

  const handleOnClickNavRoute = useCallback((selected) => {
    setSelectedRoute(selected);
  }, []);

  const showArchiveModal = () => {
    setVisible(true);
  };

  const handleConfirmModal = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancelModal = () => {
    setVisible(false);
  };

  const handleEditButton = () => {
    navigate(`/planning/routes/${id}/edit`);
  };
  const handleShowButton = () => {
    navigate(`/planning/routes/${id}/show`);
  };
  const handleFollowingButton = () => {
    navigate(`/following/routing-set/${id}`);
  };

  const showButton = (
    <Button key="btn-show" type="primary" onClick={handleShowButton}>
      Detalle
    </Button>
  );
  const editButton = (
    <Button key="btn-editar" type="primary" onClick={handleEditButton}>
      Editar
    </Button>
  );

  const followingButton = (
    <Button key="btn-following" onClick={handleFollowingButton}>
      Seguimiento
    </Button>
  );

  const archiveButton = (
    <Button key="btn-delete" type="primary" danger onClick={showArchiveModal}>
      Archivar
    </Button>
  );

  const toggleMap = () => {
    setShowMap(!showMap);
  };

  const toggleMapButton = (
    <Button
      key="btn-toggleMap"
      onClick={toggleMap}
      style={{
        width: '20px',
        height: '32px',
        padding: 0,
      }}
    >
      <FontAwesomeIcon icon={['fas', showMap ? 'angle-right' : 'angle-left']} />
    </Button>
  );

  const allUneditable = dataFormated
    .map(({ editable }) => editable)
    .every((editable) => editable === false);
  const buttonsTitle = hasPermissionUpdate && !allUneditable ? [editButton] : [showButton];
  const buttonDelete = hasPermissionDelete && [archiveButton];
  const buttonFollowing = hasPermissionFollowing && [followingButton];

  return (
    <div className="demands-container">
      <Row
        gutter={16}
        className="upper-container"
        style={{
          height: `${upperValues[currentUpperValue]}%`,
          opacity: currentUpperValue === 'hide' ? 0 : 1,
        }}
      >
        <Col
          className="upper-container table-element"
          style={{
            width: showMap ? '60%' : '100%',
          }}
        >
          <Breadcrumb breadcrumbItems={breadcrumbItems} />
          <Skeleton loading={isLoading} paragraph={{ rows: 12 }}>
            <PageHeader
              className="padding-page-header"
              onBack={() => navigate('/planning/routes')}
              title={routingSet?.name}
              extra={[buttonsTitle, buttonFollowing, buttonDelete, toggleMapButton]}
            />
            <NavRouteButtons
              size={allRoutes.length}
              onClick={handleOnClickNavRoute}
              selectedRoute={selectedRoute}
            />
            <div
              style={{
                height: `${tableValues[currentUpperValue]}%`,
                overflow: 'auto',
              }}
            >
              <TableContent
                data={dataFormated}
                loading={isLoading}
                isPlannerView={hasPermissionUpdate}
              />
            </div>
          </Skeleton>
        </Col>
        {showMap && currentLowerValue !== 'expand' && routeStops.length !== 0 && (
          <RoutesDemandMap
            routeStops={routeStops}
            allRoutes={allRoutes}
            routingSet={routingSet}
            setRestrictionsDrawerOpen={setRestrictionsDrawerOpen}
          />
        )}
      </Row>
      <RoutesDemandSynoptic
        currentLowerValue={currentLowerValue}
        setCurrentLowerValue={setCurrentLowerValue}
        setCurrentUpperValue={setCurrentUpperValue}
        routes={routes}
        setRoutes={setRoutes}
        selectedRoute={selectedRoute}
        allRoutes={allRoutes}
        setRestrictionsDrawerOpen={setRestrictionsDrawerOpen}
      />
      <ModalConfirm
        title="¿Está seguro que desea archivar el conjunto de rutas?"
        onOk={handleConfirmModal}
        onCancel={handleCancelModal}
        isModalVisible={visible}
        confirmLoading={confirmLoading}
      />
      {!isLoading && (
        <RestrictionsDrawer
          routes={routes}
          restrictionsDrawerOpen={restrictionsDrawerOpen}
          setRestrictionsDrawerOpen={setRestrictionsDrawerOpen}
          isLoading={isLoading}
        />
      )}
    </div>
  );
}

export default RoutesDemand;
