import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Card, Col, Popover, Result, Tooltip, Typography } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { selectNotifications, setNotificationsAsReaded } from 'features/app/appSlice';
import { selectCurrentOrganization } from 'features/users/userSlice';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { convertDateToDayjs, defineContentNotificationFirebase } from 'services/repeated-functions';
import './style.css';

dayjs.extend(isToday);

const { Text } = Typography;
const { Meta } = Card;
const LIMIT_NOTIFICATION = 5;

function Notifications() {
  const dispatch = useDispatch();
  const [additionalNotifications, setAdditionalNotifications] = useState(0);
  const notificationsFromStore = useSelector(selectNotifications) || [];
  const { timezone: tzOrganization, id: organizationId } = useSelector(selectCurrentOrganization);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const virtualLimit = LIMIT_NOTIFICATION + additionalNotifications;
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'notifications' };

  // restart limit of notifications after close
  useEffect(() => {
    if (!open) {
      setAdditionalNotifications(0);
    }
  }, [open]);

  const notificationsThisOrg = notificationsFromStore.filter(
    ({ organizationId: orgId }) => organizationId === orgId
  );
  const notificationsUnread = notificationsThisOrg.filter(({ status }) => status === 'unread');
  // notification are grouped
  const notificationGroup = notificationsThisOrg.reduce((summ, noti) => {
    const {
      type,
      message: { statusProcess = 'default' },
    } = noti;
    const typeStatus = `${type}_${statusProcess}`;
    const { total = 0 } = summ[typeStatus] || 0;
    return {
      ...summ,
      [typeStatus]: { ...noti, total: total + 1 },
    };
  }, {});

  const notifications = Object.values(notificationGroup);
  // order by date
  const notificationsOrdered = notifications.sort(
    (d1, d2) => new Date(d2.createdAt).getTime() - new Date(d1.createdAt).getTime()
  );

  const limitedNotifications = notificationsOrdered.slice(0, virtualLimit);
  const todayNotifications = [];
  const prevNotifications = [];

  limitedNotifications.forEach((notification) => {
    const { createdAt } = notification;
    if (convertDateToDayjs(createdAt, tzOrganization).isToday()) {
      todayNotifications.push(notification);
    } else {
      prevNotifications.push(notification);
    }
  });

  const renderTime = (createdAt, isPrevious) => {
    const createdAsDays = convertDateToDayjs(createdAt, tzOrganization);
    const nowDayjs = convertDateToDayjs(new Date(), tzOrganization);
    const seconds = nowDayjs.clone().diff(createdAsDays, 'seconds'); // diff with now
    const initialTime = nowDayjs.clone().startOf('day');
    const newTime = initialTime.add(Math.abs(seconds), 'seconds');
    const todayFormat = seconds > 3600 ? '[Hace más de] H [hora]' : '[Hace] m [min]';
    const dateFormat = isPrevious ? 'DD-MM-YYYY' : todayFormat;
    return isPrevious ? createdAsDays.format(dateFormat) : newTime.format(dateFormat);
  };

  // ToDo: maybe this will be reused by float notifications, move to repeated function
  const onClickNotification = (item) => {
    const { type } = item;
    let urlRedirect;
    switch (type) {
      case 'demands':
      case 'superAdvancedDemands':
        urlRedirect = 'planning';
        break;
      case 'routes':
        urlRedirect = 'planning/routes';
        break;
      case 'routing_job':
        urlRedirect = 'planning/jobs';
        break;
      case 'download':
      case 'reports':
        urlRedirect = 'documents';
        break;
      case 'vehicles':
        urlRedirect = 'configurations';
        break;
      case 'visits':
      case 'edit-visits':
        urlRedirect = 'locations';
        break;
      case 'bases':
      case 'edit-bases':
        urlRedirect = 'locations/base';
        break;
      default:
        break;
    }
    if (urlRedirect) {
      navigate(`/${urlRedirect}`);
      // close after redirect
      setOpen(false);
    }
  };

  const renderItem = (item, index, isPrevious) => {
    const { createdAt } = item;
    const { icon, color, title, description } = defineContentNotificationFirebase(item);
    return (
      <Card
        key={`card-${index}`}
        style={{ boxShadow: 'unset', paddingBottom: '1rem', paddingTop: '1rem', cursor: 'pointer' }}
        bodyStyle={{ padding: 0 }}
        bordered={false}
        onClick={() => onClickNotification(item)}
      >
        <Meta
          key={`meta-${index}`}
          className="notification-meta"
          style={{ title: { marginBottom: 0 } }}
          avatar={
            <FontAwesomeIcon
              icon={['fas', icon]}
              color={color}
              fontSize="30px"
              mask="circle"
              transform="shrink-9"
            />
          }
          title={title}
          description={
            <>
              <Text>{description}</Text>
              <br />
              <Text className="date-created">{renderTime(createdAt, isPrevious)}</Text>
            </>
          }
        />
      </Card>
    );
  };

  const loadMoreNotifications = () => {
    const newAditional = additionalNotifications + 1;
    setAdditionalNotifications(newAditional);
  };

  const renderContent = () => {
    let allNotifications = [];
    if (todayNotifications.length > 0) {
      allNotifications.push(
        <Text key="today-subtitle" className="notification-subtitle">
          {i18n.t('today', scopeI18n)}
        </Text>
      );
      allNotifications = [
        ...allNotifications,
        todayNotifications?.map((item, index) => renderItem(item, index)),
      ];
    }
    if (prevNotifications.length > 0) {
      allNotifications.push(
        <Text key="prev-subtitle" className="notification-subtitle">
          {i18n.t('previous', scopeI18n)}
        </Text>
      );
      allNotifications = [
        ...allNotifications,
        prevNotifications?.map((item, index) => renderItem(item, index, true)),
      ];
    }
    // add last element as other notifications
    if (notificationsOrdered.length > virtualLimit) {
      const otherNotifications = notificationsOrdered.length - virtualLimit;
      allNotifications.push(
        <Col style={{ textAlign: 'center' }} key="last-notification">
          <Text type="secondary">
            {i18n.t('additionalNotification', { ...scopeI18n, count: otherNotifications })}
          </Text>
        </Col>
      );
      allNotifications.push(
        <Button
          block
          key="button-load-more"
          onClick={loadMoreNotifications}
          style={{ marginTop: '.5rem' }}
        >
          {i18n.t('loadMore', scopeI18n)}
        </Button>
      );
    }
    if (allNotifications.length > 0) {
      return allNotifications;
    }
    return (
      <Result
        style={{ paddingTop: '6rem' }}
        icon={<FontAwesomeIcon icon={['fas', 'bell']} color="#c1c1c1" size="6x" />}
        extra={i18n.t('empty', scopeI18n)}
      />
    );
  };

  const changeStatusNotifications = (statusOpen) => {
    if (statusOpen) {
      dispatch(setNotificationsAsReaded({ organizationId }));
    }
    setOpen(statusOpen);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  return (
    <Popover
      placement="bottomRight"
      title={i18n.t('notifications', scopeI18n)}
      open={open}
      content={() => renderContent()}
      trigger="click"
      destroyTooltipOnHide // make recalc on every render
      overlayClassName="notification-popover"
      arrow={{ pointAtCenter: true }}
      onOpenChange={handleOpenChange}
    >
      <div>
        <Tooltip title={i18n.t('show', scopeI18n)} arrow={false}>
          <Badge count={notificationsUnread.length} overflowCount={5}>
            <Button
              icon={<FontAwesomeIcon icon={['fas', 'bell']} color="#c1c1c1" />}
              size="large"
              onClick={() => changeStatusNotifications(!open)}
            />
          </Badge>
        </Tooltip>
      </div>
    </Popover>
  );
}

export default Notifications;
