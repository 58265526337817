import PointsContext from 'components/DemandSets/Edit/context/PointsContext';
import L from 'leaflet';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef } from 'react';
import { CircleMarker, Marker } from 'react-leaflet';
import { coordsArrayPropTypes, pointShapePropTypes } from './LeafletMap.propType';
import PopOverComponent from './PopOverComponent';
import PopupComponent from './PopupComponent';
import './style.css';

function LeafletMarker(props) {
  const {
    index,
    coords,
    type,
    color,
    itemPosition,
    drawablesRows, // is the full point with all parameters
    markerTriggerCallback,
    draggable,
    onDrag,
    markerType,
    setSelectedPoint,
  } = props;
  const markerRef = useRef(null);
  const { collapseDemandId } = useContext(PointsContext);

  useEffect(() => {
    if (markerRef && collapseDemandId) {
      const marker = markerRef.current;
      if (marker.options.demandId === parseInt(collapseDemandId, 10)) {
        console.log('this must be jump');
      }
    }
  }, [collapseDemandId]);

  const faMarker =
    '<svg version="1" color="{mapIconColor}" aria-hidden="true" focusable="false" ' +
    'data-prefix="fas" data-icon="map-marker" class="svg-inline--fa fa-map-marker fa-w-12" ' +
    'role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 149 178">' +
    '<path fill="currentColor" d="M172.268 501.67C26.97 291.031 0 269.413 0 ' +
    '192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 ' +
    '309.67-9.535 13.774-29.93 13.773-39.464 0z"></path>' +
    '<text x="190" y="250" font-size="120pt" font-family="Arial" font-weight="bold" text-anchor="middle" fill="white">{itemNumber}</text></svg>';
  const depotIcon =
    '<svg width="23" height="30" viewBox="0 0 23 30" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M20.35 16.3602C18.5483 19.9003 15.4832 24.4476 11.6759 29.2233C6.69784 22.9792 3.04679 17.1939 1.75026 13.5507L1.75027 13.5507L1.74934 13.5481C1.52686 12.9336 1.35172 12.304 1.22533 11.6644C1.13995 10.957 1.1337 10.2426 1.20667 9.53377C1.47357 7.304 2.49248 5.22039 4.1076 3.60875L4.10762 3.60876L4.11038 3.60596C4.88377 2.82201 5.78425 2.16556 6.77651 1.66295L6.77806 1.66216C8.10585 0.983754 9.56827 0.588964 11.0671 0.504808C11.7401 0.488021 12.4135 0.515207 13.0827 0.586182C15.4297 0.884813 17.5996 1.95138 19.2357 3.6071L19.2383 3.60968C20.8539 5.22097 21.8734 7.30444 22.1409 9.53425C22.2136 10.2413 22.2076 10.9538 22.1228 11.6596C21.7632 13.2939 21.169 14.8715 20.3576 16.346L20.3537 16.353L20.35 16.3602ZM12.5783 6.13999L12.5316 6.13099H12.5209C11.9435 6.04595 11.3561 6.04635 10.7787 6.13219L10.767 6.13393L10.7554 6.13621C9.86508 6.3119 9.04111 6.72549 8.37647 7.33234C7.71171 7.93931 7.23251 8.71582 6.99459 9.57562L6.99457 9.57561L6.9933 9.58036C6.88914 9.97169 6.85087 10.3769 6.87999 10.7803C6.83658 11.451 6.97868 12.1204 7.29085 12.7186C7.50636 13.1834 7.80146 13.6084 8.16304 13.9756C8.7624 14.5914 9.51447 15.0443 10.3452 15.2916L10.3472 15.2922C10.7763 15.4181 11.2256 15.4639 11.6716 15.4277C12.1176 15.4639 12.5669 15.4181 12.996 15.2922L12.998 15.2916C13.8287 15.0443 14.5807 14.5914 15.1801 13.9756C15.5417 13.6084 15.8368 13.1834 16.0523 12.7186C16.3645 12.1204 16.5066 11.451 16.4631 10.7803C16.4923 10.3769 16.454 9.97169 16.3498 9.58036L16.3498 9.58035L16.3488 9.57638C16.1118 8.71478 15.6316 7.93686 14.9647 7.33002C14.2979 6.7233 13.471 6.31164 12.5783 6.13999Z" fill="white" stroke="#0D63CF"/>' +
    '<ellipse cx="11.7204" cy="10.5455" rx="5.90201" ry="5.62559" fill="white"/>' +
    '<g clip-path="url(#clip0_878_23959)">' +
    '<path d="M11.6615 9.32471L7.25491 12.9391V16.8417C7.25491 16.9427 7.2952 17.0396 7.3669 17.1109C7.4386 17.1823 7.53586 17.2225 7.63726 17.2225L10.3161 17.2153C10.4172 17.2149 10.5141 17.1746 10.5854 17.1032C10.6568 17.0319 10.6968 16.9353 10.6968 16.8346V14.5567C10.6968 14.4557 10.7371 14.3589 10.8088 14.2875C10.8805 14.2161 10.9778 14.176 11.0792 14.176H12.6086C12.71 14.176 12.8072 14.2161 12.879 14.2875C12.9507 14.3589 12.9909 14.4557 12.9909 14.5567V16.8306C12.9907 16.8807 13.0005 16.9304 13.0196 16.9768C13.0387 17.0231 13.0668 17.0653 13.1024 17.1008C13.1379 17.1363 13.1801 17.1645 13.2266 17.1838C13.2731 17.203 13.3229 17.2129 13.3733 17.2129L16.0505 17.2201C16.1519 17.2201 16.2492 17.18 16.3209 17.1086C16.3926 17.0372 16.4329 16.9403 16.4329 16.8394V12.9367L12.0279 9.32471C11.976 9.28294 11.9114 9.26015 11.8447 9.26015C11.778 9.26015 11.7134 9.28294 11.6615 9.32471ZM18.6206 11.781L16.6209 10.1411V6.84452C16.6209 6.76869 16.5906 6.69596 16.5367 6.64234C16.4829 6.58872 16.4098 6.55859 16.3337 6.55859H14.9995C14.9233 6.55859 14.8503 6.58872 14.7964 6.64234C14.7426 6.69596 14.7123 6.76869 14.7123 6.84452V8.57285L12.5726 6.82063C12.3672 6.65227 12.1095 6.56021 11.8435 6.56021C11.5775 6.56021 11.3197 6.65227 11.1144 6.82063L5.06401 11.781C5.00541 11.8293 4.96845 11.8988 4.96125 11.9742C4.95405 12.0496 4.9772 12.1248 5.02561 12.1832L5.63513 12.9208C5.68353 12.9792 5.75325 13.0162 5.82899 13.0235C5.90473 13.0308 5.98029 13.0079 6.03908 12.9598L11.6615 8.34825C11.7133 8.30668 11.7778 8.28401 11.8443 8.28401C11.9108 8.28401 11.9753 8.30668 12.0271 8.34825L17.6495 12.959C17.7082 13.0072 17.7837 13.0302 17.8594 13.0231C17.9352 13.0159 18.0049 12.9791 18.0535 12.9208L18.663 12.1832C18.6869 12.1541 18.7049 12.1206 18.7158 12.0846C18.7267 12.0485 18.7304 12.0107 18.7266 11.9733C18.7228 11.9358 18.7116 11.8995 18.6937 11.8664C18.6758 11.8333 18.6515 11.804 18.6222 11.7802L18.6206 11.781Z" fill="#0D63CF"/>' +
    '</g>' +
    '<defs>' +
    '<clipPath id="clip0_878_23959">' +
    '<rect width="13.7685" height="10.6615" fill="white" transform="translate(4.95996 6.56055)"/>' +
    '</clipPath>' +
    '</defs>' +
    '</svg>';

  const iconSettings = {
    mapIconUrl: ['DEPOT', 'RETURN_TO_DEPOT'].includes(type) ? depotIcon : faMarker,
    mapIconColor: color,
    itemNumber: itemPosition || '',
  };
  const icon = L.divIcon({
    className: 'leaflet-data-marker',
    html: L.Util.template(iconSettings.mapIconUrl, iconSettings),
    iconAnchor: [12, 32],
    iconSize: [25, 30],
    popupAnchor: [0, -28],
  });

  const showPopupFun = (e) => {
    if (markerTriggerCallback) {
      // respond only on click
      if (e.type === 'click') {
        // trigger callback with data value from marker
        markerTriggerCallback(e.target.options.data);
      }
    }
    e.target.openPopup();
  };

  const doDraggablFun = (e) => {
    if (draggable) {
      if (onDrag) {
        onDrag(e.target.getLatLng());
      } else {
        console.log(e.target.getLatLng());
        console.log('Do something with the drag');
      }
    }
  };

  const showPopup = drawablesRows.usedRows !== undefined;

  const normalMarker = () => (
    <Marker
      ref={markerRef}
      position={coords}
      key={index}
      data={index}
      demandId={index}
      icon={icon}
      draggable={draggable}
      eventHandlers={{
        mouseover: showPopupFun,
        click: showPopupFun,
        dragend: doDraggablFun,
      }}
    >
      {showPopup ? <PopupComponent key={`pc-${index}`} drawablesRows={drawablesRows} /> : null}
    </Marker>
  );

  const circleMarker = () => (
    <CircleMarker
      key={index}
      center={coords}
      radius={5}
      eventHandlers={{
        click: showPopupFun,
        dragend: doDraggablFun,
      }}
    >
      {showPopup ? (
        <PopOverComponent
          key={`pc-${index}`}
          drawablesRows={drawablesRows}
          setSelectedPoint={setSelectedPoint}
        />
      ) : null}
    </CircleMarker>
  );
  return markerType === 'normal' ? normalMarker() : circleMarker();
}

LeafletMarker.defaultProps = {
  index: 0,
  coords: [],
  type: '',
  color: '',
  itemPosition: '',
  drawablesRows: {},
  markerTriggerCallback: undefined,
  draggable: false,
  onDrag: undefined,
  markerType: 'normal',
  setSelectedPoint: () => {},
};

LeafletMarker.propTypes = {
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  coords: coordsArrayPropTypes,
  type: PropTypes.string,
  color: PropTypes.string,
  itemPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  drawablesRows: pointShapePropTypes,
  markerTriggerCallback: PropTypes.func,
  draggable: PropTypes.bool,
  onDrag: PropTypes.func,
  markerType: PropTypes.string,
  setSelectedPoint: PropTypes.func,
};

export default LeafletMarker;
