import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Select, Tooltip, theme } from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import { useContext } from 'react';

function LanguageSelector() {
  const { setLocaleSelected, localeSelected, getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const { token } = theme.useToken();

  const onChangeLanguage = (value) => {
    setLocaleSelected(value);
  };

  const optionsLanguage = [
    {
      value: 'es',
      label: i18n.t('spanishAb'),
      title: i18n.t('spanish'),
    },
    {
      value: 'en',
      label: i18n.t('englishAb'),
      title: i18n.t('english'),
    },
    // {
    //   value: 'pt-BR',
    //   label: 'PORT',
    //   title: 'Português',
    //   disabled: true,
    // },
  ];
  return (
    <Col className="language-container" xs={0} md={24}>
      <Select
        className="language-select"
        defaultValue={localeSelected}
        size="large"
        style={{ width: 84 }}
        onChange={onChangeLanguage}
        title={i18n.t('commons.changeLanguage')}
        options={optionsLanguage}
      />
      {localeSelected !== 'es' ? (
        <Tooltip title={i18n.t('workingForTranslate')}>
          <FontAwesomeIcon
            icon={['fas', 'circle-info']}
            color={token.colorPrimary}
            style={{ marginLeft: '.5rem', paddingTop: '.5rem', fontSize: 20 }}
          />
        </Tooltip>
      ) : (
        ''
      )}
    </Col>
  );
}

export default LanguageSelector;
