import { AutoComplete, Form } from 'antd';
import PointsContext from 'components/DemandSets/Edit/context/PointsContext';
import LocaleContext from 'components/locale/LocaleContext';
import { useAutocompleteMutation } from 'features/georreference/georreferenceApiSlice';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import TitleHelp from '../TitleHelp';

function AutocompleteAddress(props) {
  const { setAddress, demandId, fromLocations, customOnChange, client } = props;
  const form = Form.useFormInstance();
  const [options, setOptions] = useState([]);
  const [autocomplete] = useAutocompleteMutation();
  const { mapPoints, setMapPoints, onDrag, setMoveToPosition } = useContext(PointsContext);
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'form.location' };

  const changeLatLng = (newCoords, params) => {
    const objectId = demandId || params?.nid;
    const notFounded = mapPoints.filter((item) => item.key === objectId).length === 0;
    // is adding a demand
    if (notFounded) {
      const obj = {
        key: objectId,
        lat: `${newCoords.destination_latitude}`,
        lng: `${newCoords.destination_longitude}`,
        Cod: params?.nid,
        Nombre: params?.name,
        usedRows: ['Cod', 'Nombre'],
        draggable: true,
        onDrag: (latLon) => {
          onDrag(latLon, objectId);
        },
      };
      setMapPoints((oldMapPoints) => [...oldMapPoints, obj]);
    } else {
      setMapPoints(
        [...mapPoints].map((object) => {
          if (object.key === objectId) {
            return {
              ...object,
              lat: `${newCoords.destination_latitude}`,
              lng: `${newCoords.destination_longitude}`,
            };
          }
          return object;
        })
      );
    }
    if (!fromLocations) {
      setMoveToPosition([newCoords.destination_latitude, newCoords.destination_longitude]);
    }
  };

  const normalizeAddress = (result) => {
    const resultItems = result?.items;
    const items = resultItems?.map((resultItem) => {
      const { address, position } = resultItem;
      return {
        key: resultItem?.id,
        value: address?.label,
        label: address?.label,
        address,
        position,
      };
    });
    return items;
  };

  const onSearch = async (searchText) => {
    const result = searchText ? await autocomplete({ address: searchText, client }).unwrap() : [];
    const resultOptions = normalizeAddress(result);
    setOptions(!searchText ? [] : resultOptions);
  };

  const onSelect = (_, selectedOption) => {
    const {
      address: { street, houseNumber, postalCode, city, countryName, state, county, countryCode },
      position,
    } = selectedOption;
    form.setFieldValue('zip_code', postalCode);
    let addressObj = { country: countryName, province: state, city, street, number: houseNumber };
    if (countryCode === 'PER') {
      addressObj = {
        country: countryName,
        province: state,
        city: county,
        town: city,
        street,
        number: houseNumber,
      };
    } else if (countryCode === 'COL') {
      addressObj = { country: countryName, province: county, city, street, number: houseNumber };
    }
    setAddress((prevAddress) => ({
      ...prevAddress,
      ...addressObj,
    }));

    if (position) {
      const formValues = form.getFieldsValue();
      const latLng = !fromLocations
        ? {
            destination_latitude: position.lat,
            destination_longitude: position.lng,
          }
        : { latForm: position.lat, lngForm: position.lng };
      form.setFieldsValue(latLng);
      if (fromLocations) {
        customOnChange(latLng);
      } else {
        changeLatLng(latLng, { ...addressObj, nid: formValues?.nid, name: formValues?.name });
      }
    } else {
      console.error('Cannot get data: ', position);
    }
  };

  return (
    <Form.Item
      label={
        <TitleHelp
          title={i18n.t('searchAddress', scopeI18n)}
          helpText={i18n.t('helps.searchAddress', scopeI18n)}
        />
      }
      name={['addr', 'autocomplete']}
    >
      <AutoComplete
        options={options}
        onSelect={onSelect}
        onSearch={onSearch}
        placeholder={i18n.t('helps.typeToSearch', scopeI18n)}
        allowClear
      />
    </Form.Item>
  );
}

AutocompleteAddress.defaultProps = {
  setAddress: () => {},
  demandId: undefined,
  fromLocations: false,
  customOnChange: () => {},
  client: '',
};

AutocompleteAddress.propTypes = {
  setAddress: PropTypes.func,
  demandId: PropTypes.number,
  fromLocations: PropTypes.bool,
  customOnChange: PropTypes.func,
  client: PropTypes.string,
};

export default AutocompleteAddress;
