import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import { loadsFromOrgType } from 'types';

const { Option } = Select;
const { Text } = Typography;

function DemandDetailForm(props) {
  const { loadsFromOrg } = props;
  const form = Form.useFormInstance();
  const [demandDetailsData, setDemandDetailsData] = useState();

  useEffect(() => {
    const { demandDetails } = form.getFieldsValue();
    setDemandDetailsData(demandDetails);
  }, [form]);

  const getDemandDetailData = (field) => {
    const data = demandDetailsData?.[field.key];
    return {
      name: data ? `Producto ${data?.code}` : 'Agregar Producto',
    };
  };

  const itemsCollapse = (fields, remove) =>
    fields.map((field) => {
      const { name } = getDemandDetailData(field);
      return {
        label: name,
        key: field.key,
        children: (
          <>
            <Row justify="end">
              <Button
                type="primary"
                danger
                onClick={() => remove(field.name)}
                className="trash-btn"
                title="archivar"
              >
                <FontAwesomeIcon icon={['fas', 'trash']} color="#FFFFFF" />
              </Button>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="SKU"
                  name={[field.name, 'code']}
                  required
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Código producto" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Descripción"
                  name={[field.name, 'description']}
                  required
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Descripción producto" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Cantidad"
                  name={[field.name, 'quantity']}
                  required
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    min={0}
                    addonAfter={
                      <Select defaultValue="Unidad" className="unit-options">
                        <Option value="Unidad">Unidad</Option>
                      </Select>
                    }
                    placeholder="Cantidad"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation="left" orientationMargin="0">
              <Text strong>Cargas</Text>
            </Divider>
            <Form.List
              name={[field.name, 'loads']}
              rules={[
                () => ({
                  validator(_, loads) {
                    if (loads && loads.length > loadsFromOrg.length) {
                      return Promise.reject(new Error('Número máximo de cargas alcanzado'));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              {(loads, { add: addLoad, remove: removeLoad }, { errors }) => (
                <>
                  <Row justify="end">
                    <Col span={8}>
                      <Form.ErrorList errors={errors} />
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        onClick={() => addLoad()}
                        disabled={loads.length === loadsFromOrg.length}
                      >
                        Añadir carga
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    {loads.map(({ key, name: loadName, ...restField }, loadIndex) => {
                      const { isListField } = restField;
                      const loadsFromOrgData = loadsFromOrg.find(
                        (load) => load.key === loadIndex + 1
                      );
                      return (
                        <Col span={8} key={key}>
                          <Space
                            key={key}
                            style={{
                              display: 'flex',
                            }}
                          >
                            <Form.Item
                              isListField={isListField}
                              label={loadsFromOrgData?.name}
                              name={[loadName, 'load']}
                              required={key === 0}
                              rules={[{ required: key === 0 }]}
                            >
                              <InputNumber
                                placeholder={loadsFromOrgData?.name}
                                addonAfter={loadsFromOrgData?.unit}
                              />
                            </Form.Item>
                            {loads.length > 1 && (
                              <FontAwesomeIcon
                                className="dynamic-delete-button"
                                icon={['fas', 'circle-minus']}
                                onClick={() => removeLoad(loadName)}
                              />
                            )}
                          </Space>
                        </Col>
                      );
                    })}
                  </Row>
                </>
              )}
            </Form.List>
          </>
        ),
      };
    });

  return (
    <Form.List name="demandDetails">
      {(fields, { add, remove }) => {
        return (
          <>
            <Row justify="end">
              <Col style={{ justifyContent: 'flex-end' }}>
                <Button
                  type="primary"
                  onClick={() =>
                    add({
                      loads: loadsFromOrg.map(() => {
                        return { load: null };
                      }),
                    })
                  }
                >
                  Añadir producto
                </Button>
              </Col>
            </Row>
            <br />
            <Collapse accordion items={itemsCollapse(fields, remove)} />
          </>
        );
      }}
    </Form.List>
  );
}

DemandDetailForm.defaultProps = {
  loadsFromOrg: [],
};

DemandDetailForm.propTypes = {
  loadsFromOrg: loadsFromOrgType,
};

export default DemandDetailForm;
