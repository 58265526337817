import Hotjar from '@hotjar/browser';
import { App, ConfigProvider, notification } from 'antd';
import enUs from 'antd/lib/locale/en_US';
import esES from 'antd/lib/locale/es_ES';
import Account from 'components/Accounts';
import ConfirmAccount from 'components/Accounts/ConfirmAccount';
import ForgotPassword from 'components/Accounts/ForgotPassword';
import RecoverPassword from 'components/Accounts/RecoverPassword';
import BaseLocations from 'components/BaseLocations';
import Configurations from 'components/Configurations';
import DemandSetsEdit from 'components/DemandSets/Edit';
import DemoLayout from 'components/DemoLayout';
import Documents from 'components/Documents';
import DocumentsList from 'components/Documents/List';
import Following from 'components/Following';
import Layout from 'components/Layout';
import Locations from 'components/Locations';
import LoginScreen from 'components/LoginScreen';
import MainLayout from 'components/MainLayout';
import Organizations from 'components/Organizations';
import OrganizationsCreationSteps from 'components/Organizations/CreationSteps';
import OrganizationsDashboard from 'components/Organizations/Dashboard';
import OrganizationsDashboardList from 'components/Organizations/Dashboard/List';
import Plans from 'components/Plans';
import RolesComponent from 'components/Roles';
import RoutesComponent from 'components/Routes';
import RouteList from 'components/Routes/List';
import RouteContent from 'components/Routes/RoutesContent';
import RoutesDemand from 'components/RoutesDemand';
import RouteJob from 'components/RoutesJob';
import RoutesJobList from 'components/RoutesJob/List';
import RouteJobData from 'components/RoutesJob/RouteJobData';
import SecondaryLayout from 'components/SecondaryLayout';
import SystemComponent from 'components/System';
import Tracking from 'components/Tracking';
import UsersComponent from 'components/Users';
import FleetsComponent from 'components/Vehicles';
import VisitLocations from 'components/VisitLocations';
import { LoadingProvider } from 'components/common/Loading/LoadingContext';
import EmptyContent from 'components/layout/EmptyContent';
import ErrorBoundary from 'components/layout/ErrorBoundary';
import UnauthorizedError from 'components/layout/Errors/Unauthorized';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import RequireAuth from 'features/auth/RequireAuth';
import { clearErrors } from 'features/errors/errorsSlice';
import { selectCurrentUser } from 'features/users/userSlice';
import { I18n } from 'i18n-js';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { getAllIndexFromArray, userIsOnlyDriver, userIsOptimal } from 'services/repeated-functions';
import { buildAbilityFor } from 'utils/caslAbility';
import CaslContext from 'utils/caslContext';
import Driver from './components/Driver';
import DriverLayout from './components/DriverLayout';
import FollowingGeneral from './components/Following/General';
import FollowingRouteDetail from './components/Following/RouteDetail';
import FollowingRoutingSet from './components/Following/RoutingSet';
import Reports from './components/Reports';
import DownloadComponent from './components/Reports/Downloads';
import PanelsComponent from './components/Reports/Panels';
import PanelEdit from './components/Reports/Panels/Edit';
import PanelNew from './components/Reports/Panels/New';
import PanelShow from './components/Reports/Panels/Show';
import RoutesDemandEditor from './components/RoutesDemand/Editor';
import RoutesDemandShow from './components/RoutesDemand/Show';
import TourContext from './components/layout/TourComponent/TourContext';
import LocaleContext from './components/locale/LocaleContext';
import localeTranslate from './components/locale/translate';
import { setNotificationToken, setNotifications } from './features/app/appSlice';
import { getFirebaseToken } from './services/firebase-init';
import socket from './socket';

// add utc for all dayjs instances
dayjs.extend(utc);

const Planners = function Planners() {
  const [isOnline, setIsOnline] = useState(true);
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser) || { actions: [] };
  const ability = buildAbilityFor(currentUser);
  const { currentOrganizationId, enableTour, organization, notificationToken } = useSelector(
    selectCurrentUser
  ) || {
    actions: [],
  };
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [vehiclesPositionEvents, setVehiclesPositionEvents] = useState([]);
  const userHasOptimalRolesCheck = userIsOptimal(currentUser);
  const userIsDriver = userIsOnlyDriver(currentUser);
  const demoIsExpired = organization?.demoIsExpired && !userHasOptimalRolesCheck;
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    function onVehiclesPositionEvent(value) {
      const { organizationId } = value;
      if (isConnected) {
        setVehiclesPositionEvents((previous) => {
          const timeNow = dayjs().utc();
          const previousFiltered = previous.filter((prev) => {
            const { timestamp } = prev;
            const timestampDayjs = dayjs(timestamp).utc();
            const limitDayjs = timestampDayjs.add(
              process.env.REACT_APP_VEHICLE_POSITION_LIMIT,
              'seconds'
            );
            return limitDayjs.isAfter(timeNow);
          });
          if (currentOrganizationId && organizationId === currentOrganizationId) {
            return [...previousFiltered, value];
          }
          if (!currentOrganizationId) {
            return [...previousFiltered, value];
          }
          return previousFiltered;
        });
      }
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('vehicles-position', onVehiclesPositionEvent);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('vehicles-position', onVehiclesPositionEvent);
    };
  }, [currentOrganizationId, isConnected]);

  // add notification from click on background notification
  const setNotificationEvent = (message) => {
    const { data: payload } = message;
    if (payload.fromNotification === true) {
      dispatch(setNotifications({ notification: payload.data }));
    }
  };
  // this duplicate notification
  navigator.serviceWorker.addEventListener('message', setNotificationEvent, false);

  getFirebaseToken().then((firebaseToken) => {
    if (firebaseToken) {
      if (notificationToken && notificationToken !== firebaseToken) {
        api.warning({
          message: 'No te podemos enviar notificaciones',
          description: 'Por favor vuelve a iniciar sesión para recibir notificaciones.',
          duration: 15,
        });
      }
      console.log('firebaseToken OK');
      dispatch(setNotificationToken({ notificationToken: firebaseToken }));
    }
  });

  window.addEventListener('offline', () => {
    setIsOnline(false);
  });

  window.addEventListener('online', () => {
    setIsOnline(true);
  });

  window.onpopstate = () => dispatch(clearErrors());

  // ToDo: check how to do this from module and return route with children
  const allConfigurationsFromArray = getAllIndexFromArray(
    ['fleets', 'users', 'roles', 'organizations', 'system'],
    ability
  );
  const allLocationsFromArray = getAllIndexFromArray(['visits', 'base'], ability);
  const allReportsFromArray = getAllIndexFromArray(['panels', 'reports'], ability);
  const allRoutesFromArray = getAllIndexFromArray(['planning', 'routes', 'jobs'], ability);
  const allFollowingFromArray = getAllIndexFromArray(['following'], ability);
  const {
    fleets: fleetIndexPath,
    users: userIndexPath,
    roles: roleIndexPath,
    system: systemIndexPath,
    organizations: organizationIndexPath,
  } = allConfigurationsFromArray;
  const { visits: visitIndexPath, base: baseIndexPath } = allLocationsFromArray;
  const { panels: panelIndexPath, reports: reportIndexPath } = allReportsFromArray;
  const {
    routes: routeIndexPath,
    jobs: jobIndexPath,
    planning: planningIndexPath,
  } = allRoutesFromArray;
  const { following: followingIndexPath } = allFollowingFromArray;
  const { index: fleetIndex, path: fleetPath } = fleetIndexPath;
  const { index: userIndex, path: userPath } = userIndexPath;
  const { index: roleIndex, path: rolePath } = roleIndexPath;
  const { index: organizationIndex, path: organizationPath } = organizationIndexPath;
  const { index: systemIndex, path: systemPath } = systemIndexPath;
  const { index: visitIndex, path: visitPath } = visitIndexPath;
  const { index: baseIndex, path: basePath } = baseIndexPath;
  const { index: panelIndex, path: panelPath } = panelIndexPath;
  const { index: downloadsIndex, path: downloadsPath } = reportIndexPath;
  const { index: planningIndex, path: planningPath } = planningIndexPath;
  const { index: routesIndex, path: routesPath } = routeIndexPath;
  const { index: jobsIndex, path: jobsPath } = jobIndexPath;
  const { index: followingIndex, path: followingPath } = followingIndexPath;

  const [tourOpen, setTourOpen] = useState(enableTour);
  const tourMemo = useMemo(() => {
    const fns = {};
    return {
      addReference: (name, fn) => {
        fns[name] = fn;
      },
      getReference: (name) => {
        return fns[name];
      },
      getAllReferences: () => fns,
      tourOpen,
      setTourOpen,
    };
  }, [tourOpen]);

  const showExperimentalDevelop = process.env.REACT_APP_SHOW_EXPERIMENTAL_DEVELOP;
  // locale config
  const getLocaleFromBrowser = () => {
    let locale = 'es';
    // use default es if flag isn't true
    if (`${showExperimentalDevelop}` !== 'true') {
      return locale;
    }
    if (navigator.language.includes('en')) {
      locale = 'en';
    }
    return locale;
  };
  const [localeSelected, setLocaleSelected] = useState(getLocaleFromBrowser());
  // ToDo: define this dynamic, for other languages
  const localeAnt = localeSelected === 'es' ? esES : enUs;
  const localeMemo = useMemo(() => {
    const i18n = new I18n(localeTranslate);
    i18n.locale = localeSelected;
    i18n.missingBehavior = 'guess';
    const setLanguage = (newLanguage) => {
      i18n.locale = newLanguage;
    };
    const getI18n = () => {
      return i18n;
    };
    return {
      localeSelected,
      setLocaleSelected,
      setLanguage,
      getI18n,
    };
  }, [localeSelected]);

  const siteId = process.env.REACT_APP_HOTJAR_ID;
  const hotjarVersion = 6;
  Hotjar.init(siteId, hotjarVersion, {
    debug: process.env.REACT_APP_HOTJAR_DEBUG || false,
  });

  const routeNotFound = (indexes) => {
    return (
      <Route
        index={indexes.every((index) => !index)}
        element={<ErrorBoundary status={404} allView />}
      />
    );
  };

  const trackingView = (
    <Route element={<SecondaryLayout isOffline={!isOnline} />}>
      <Route path="/tracking/:token">
        <Route
          index
          element={
            <Tracking isConnected={isConnected} vehiclesPositionEvents={vehiclesPositionEvents} />
          }
        />
      </Route>
    </Route>
  );

  const logginRoutes = (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<LoginScreen />} />
        <Route path="/account" element={<Account />}>
          <Route path="confirm/:userName" element={<ConfirmAccount />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="recover-password/:userName" element={<RecoverPassword />} />
        </Route>
        {trackingView}
        <Route path="*" element={<ErrorBoundary status={404} />} />
      </Route>
    </Routes>
  );

  const mainLayoutRoutes = (
    <>
      <Route path="/" element={<Navigate to="/organizations" />} />
      <Route element={<MainLayout isOffline={!isOnline} />}>
        <Route path="/planning" element={<RoutesComponent />}>
          <Route index={planningIndex} path={planningPath} element={<RouteContent />} />
          <Route index={routesIndex} path={routesPath} element={<RouteList />} />
          <Route index={jobsIndex} path={jobsPath} element={<RoutesJobList />} />
          <Route path="jobs/:jobId/:method/:jobDbId" element={<RouteJobData />} />
          <Route path="jobs/:id" element={<RouteJob />} />
          <Route path="demand-sets/:id/edit" element={<DemandSetsEdit />} />
          <Route path="routes/:id" element={<RoutesDemand />} />
          <Route path="routes/:id/edit" element={<RoutesDemandEditor />} />
          <Route path="routes/:id/show" element={<RoutesDemandShow />} />
          <Route path="unauthorized" element={<UnauthorizedError />} />
          {routeNotFound([planningIndex, routesIndex, jobsIndex])}
        </Route>
        <Route path="/following" element={<Following />}>
          <Route
            index={followingIndex}
            path={followingPath}
            element={
              <FollowingGeneral
                isConnected={isConnected}
                vehiclesPositionEvents={vehiclesPositionEvents}
              />
            }
          />
          <Route
            path="routing-set/:id"
            element={
              <FollowingRoutingSet
                isConnected={isConnected}
                vehiclesPositionEvents={vehiclesPositionEvents}
              />
            }
          />
          <Route
            path="route-detail/:id"
            element={
              <FollowingRouteDetail
                isConnected={isConnected}
                vehiclesPositionEvents={vehiclesPositionEvents}
              />
            }
          />
          {routeNotFound([followingIndex])}
        </Route>
        <Route path="/locations" element={<Locations />}>
          <Route index={visitIndex} path={visitPath} element={<VisitLocations />} />
          <Route index={visitIndex} path={`${visitPath}map`} element={<VisitLocations />} />
          <Route index={baseIndex} path={basePath} element={<BaseLocations />} />
          {routeNotFound([visitIndex, baseIndex])}
        </Route>
        <Route path="/reports" element={<Reports />}>
          <Route index={panelIndex} path={panelPath} element={<PanelsComponent />} />
          <Route index={`${panelIndex}/detail`} path="panels/:id" element={<PanelShow />} />
          <Route index={`${panelIndex}/edit`} path="panels/:id/edit" element={<PanelEdit />} />
          <Route index={`${panelIndex}/new`} path="panel/new" element={<PanelNew />} />
          <Route index={downloadsIndex} path={downloadsPath} element={<DownloadComponent />} />
          {routeNotFound([downloadsIndex, panelIndex])}
        </Route>
        <Route path="/support" element={<EmptyContent />} />
        <Route path="/new-route" element={<EmptyContent />} />
        <Route path="/configurations" element={<Configurations />}>
          <Route index={fleetIndex} path={fleetPath} element={<FleetsComponent />} />
          <Route path="fleets" element={<FleetsComponent />} />
          <Route index={roleIndex} path={rolePath} element={<RolesComponent />} />
          <Route index={userIndex} path={userPath} element={<UsersComponent />} />
          <Route index={organizationIndex} path={organizationPath} element={<Organizations />} />
          <Route index={systemIndex} path={systemPath} element={<SystemComponent />} />
          <Route path="unauthorized" element={<UnauthorizedError />} />
        </Route>
        <Route path="/documents" element={<Documents />}>
          <Route index element={<DocumentsList />} />
        </Route>
      </Route>
    </>
  );

  const secondaryLayoutRoutes = (
    <Route element={<SecondaryLayout isOffline={!isOnline} />}>
      <Route path="/organizations" element={<OrganizationsDashboard />}>
        <Route index element={<OrganizationsDashboardList />} />
        <Route path="creation-steps/:id?/:step?" element={<OrganizationsCreationSteps />} />
      </Route>
    </Route>
  );

  const plansView = (
    <Route element={<DemoLayout isOffline={!isOnline} />}>
      <Route exact path="/plans">
        <Route index element={<Plans />} />
      </Route>
    </Route>
  );

  const driverView = (
    <Route element={<DriverLayout />}>
      <Route path="/">
        <Route index element={<Driver />} />
      </Route>
    </Route>
  );

  const loggedRoutes = (
    <Routes>
      <Route element={<Layout />}>
        <Route path="*" element={<ErrorBoundary status={404} allView />} />
        <Route element={<RequireAuth isOffline={!isOnline} />}>
          <Route path="*" element={<EmptyContent />} />
          <Route path="/unauthorized" element={<UnauthorizedError />} />
          {!demoIsExpired && !userIsDriver && mainLayoutRoutes}
          {!demoIsExpired && !userIsDriver && trackingView}
          {demoIsExpired && plansView}
          {!demoIsExpired && userIsDriver && driverView}
          {secondaryLayoutRoutes}
        </Route>
      </Route>
    </Routes>
  );

  return (
    <ConfigProvider
      locale={localeAnt}
      theme={{
        token: {
          colorPrimary: '#9825F2',
          colorLink: '#9825F2',
          colorSuccess: '#30BA00',
          colorWarning: '#FAAD14',
          colorError: '#E30001',
          colorInfo: '#46A9F1',
        },
      }}
    >
      <App>
        <LocaleContext.Provider value={localeMemo}>
          <LoadingProvider>
            <CaslContext.Provider value={ability}>
              <TourContext.Provider value={tourMemo}>
                {contextHolder}
                {currentUser?.id ? loggedRoutes : logginRoutes}
              </TourContext.Provider>
            </CaslContext.Provider>
          </LoadingProvider>
        </LocaleContext.Provider>
      </App>
    </ConfigProvider>
  );
};

export default Planners;
