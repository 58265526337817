import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  App,
  Avatar,
  Button,
  Col,
  DatePicker,
  Row,
  Skeleton,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useMoveTimeRouteMutation } from 'features/routes/routesApiSlice';
import { selectCurrentOrganization } from 'features/users/userSlice';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ColorByOrder } from 'services/repeated-functions';
import Assigment from './Assigment';

dayjs.extend(customParseFormat);
const { Text } = Typography;

function TableContent(props) {
  const { id } = useParams();
  const { data, loading, isPlannerView } = props;
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isReloading, setIsReloading] = useState(false);
  const [route, setRoute] = useState();
  const formatTime = 'DD-MM-YY HH:mm';
  const [moveTimeRoute] = useMoveTimeRouteMutation();
  const { message } = App.useApp();
  const { timezone: tzOrganization } = useSelector(selectCurrentOrganization);

  const onChangeTime = async (record, newTime) => {
    setIsReloading(true);
    message.open({
      key: 'loading-msg',
      type: 'loading',
      content: 'Actualizando tiempos...',
    });

    const { id: routeId } = record;
    if (newTime) {
      const body = {
        id: routeId,
        body: {
          newTime,
        },
      };
      await moveTimeRoute(body)
        .unwrap()
        .then(() => {
          message.destroy('loading-msg');
          message.success('Tiempos actualizados');
          setIsReloading(false);
        })
        .catch(() => {
          setIsReloading(false);
          message.destroy('loading-msg');
          message.error('No se pudo actualizar los tiempos de la ruta');
        });
    }
  };

  const openAssigment = (record) => {
    setRoute(record);
    setDrawerVisible(true);
  };

  const setAssigmentDrawer = (boolValue) => {
    setDrawerVisible(boolValue);
    if (!boolValue) {
      setRoute();
    }
  };

  const assignmentInfo = (record) => {
    const { driver, vehicle } = record;
    const fullNameDriver = driver?.id ? `${driver?.fullName}` : '';
    return driver?.id ? (
      <Tooltip title={fullNameDriver}>
        <Row>
          <Col span={24}>
            <Text ellipsis className="name-text-driver">
              {fullNameDriver}
            </Text>
          </Col>
          <Col span={24}>
            <Text>{`/ ${vehicle?.plate}`}</Text>
          </Col>
        </Row>
      </Tooltip>
    ) : (
      'Sin asignar'
    );
  };

  const buttonAssignment = (record, color) => {
    const { driver } = record;
    const colorRoute = driver ? ColorByOrder(record.routeOrder) : '';
    return (
      <Button
        size="middle"
        style={{ width: '100%', height: 'auto', borderColor: color, maxWidth: '150px' }}
        onClick={() => openAssigment(record)}
      >
        <Space>
          <Avatar
            size={24}
            icon={<FontAwesomeIcon icon={['fa', 'user']} />}
            alt="Imagen de perfil"
            src={driver?.photo}
            style={{
              borderColor: colorRoute,
            }}
            className="ant-avatat-assign-button"
          />
          {assignmentInfo(record)}
        </Space>
      </Button>
    );
  };

  const info = (record, color) => {
    const { driver } = record;
    const fullNameDriver = driver?.id ? `${driver?.fullName}` : '';
    return (
      <Tooltip title={fullNameDriver}>
        <Button
          size="middle"
          style={{ width: '100%', height: 'auto', borderColor: color }}
          disabled
        >
          <Space>
            <Avatar
              size={24}
              icon={<FontAwesomeIcon icon={['fa', 'user']} />}
              alt="Imagen de perfil"
              src={record.driver?.photo}
            />
            {assignmentInfo(record)}
          </Space>
        </Button>
      </Tooltip>
    );
  };

  const dateToDayjsTz = (date, timezone) => {
    return dayjs(date).tz(timezone);
  };

  const maxDayRange = 1;
  const disabledDate = (current, valueToUse) => {
    const tooLate =
      current > dateToDayjsTz(valueToUse, tzOrganization).add(maxDayRange, 'days').endOf('day');
    const tooEarly =
      current <
      dateToDayjsTz(valueToUse, tzOrganization).subtract(maxDayRange, 'days').startOf('day');
    return !!tooEarly || !!tooLate;
  };

  const renderTimePicker = (record) => {
    const { startsAt, endsAt, action } = record;
    const isDelivery = action === 'delivery';
    const startsAsDayjs = dateToDayjsTz(startsAt, tzOrganization);
    const endsAtAsDayjs = dateToDayjsTz(endsAt, tzOrganization);
    const valueToUse = isDelivery ? startsAsDayjs : endsAtAsDayjs;
    const renderElement = (
      <Space>
        {!isDelivery && (
          <Tooltip title="Hora de inicio">
            <Text type="secondary">{`${startsAsDayjs.format(formatTime)}`}</Text>
          </Tooltip>
        )}
        <DatePicker
          format={formatTime}
          value={valueToUse}
          allowClear={false}
          disabledDate={(current) => disabledDate(current, valueToUse)}
          onChange={(values) => {
            onChangeTime(record, values);
          }}
          size="small"
          style={{ width: 130 }}
          placeholder={`Hora ${isDelivery ? 'inicial' : 'final'}`}
          showTime
        />
        {isDelivery && (
          <Tooltip title="Hora de fin">
            <Text type="secondary" style={{ fontSize: 14 }}>{`${endsAtAsDayjs.format(
              formatTime
            )}`}</Text>
          </Tooltip>
        )}
      </Space>
    );
    return isReloading ? <Skeleton.Input active /> : renderElement;
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'route',
      key: 'route',
      width: 100,
      render: (_text, record) => (
        <Space>
          {id}-{record.routeOrder}
          {record.relaxedConstraints && (
            <Tooltip title="Ruta aislada por contener una demanda infactible. Revisa capacidades u otras restricciones.">
              <FontAwesomeIcon icon={['fa', 'triangle-exclamation']} color="#faad14" />
            </Tooltip>
          )}
        </Space>
      ),
    },
    {
      title: 'Hora',
      dataIndex: 'startsAt',
      key: 'startsAt-endsAt',
      onCell: () => {
        return {
          style: {
            width: '300px',
          },
        };
      },
      render: (_text, record) => {
        const { startsAt, endsAt, editable } = record;
        return isPlannerView && editable ? (
          renderTimePicker(record)
        ) : (
          <Space>
            {dateToDayjsTz(startsAt, tzOrganization).format(formatTime)}
            <FontAwesomeIcon icon={['fa', 'arrow-right']} />
            {dateToDayjsTz(endsAt, tzOrganization).format(formatTime)}
          </Space>
        );
      },
    },
    {
      title: 'Tiempo',
      dataIndex: 'travelTime',
      key: 'travelTime',
      width: 90,
    },
    {
      title: (
        <Tooltip title="Total de paradas">
          <FontAwesomeIcon icon={['fa', 'map-marker']} />
        </Tooltip>
      ),
      dataIndex: 'totalStops',
      key: 'totalStops',
      onCell: () => {
        return {
          style: {
            width: '60px',
          },
        };
      },
    },
    {
      title: 'Acción',
      dataIndex: 'action',
      key: 'action',
      width: 100,
      render: (text) => (text === 'delivery' ? 'Llevada' : 'Recogida'),
    },
    {
      title: 'Asignación',
      dataIndex: 'assignment',
      key: 'assignment',
      onCell: () => {
        return {
          style: {
            width: '150px',
          },
        };
      },
      render: (_text, record) => {
        const isAssigned = record.driver?.id;
        const color = isAssigned ? ColorByOrder(record.routeOrder) : '';
        return isPlannerView && record.editable
          ? buttonAssignment(record, color)
          : info(record, color);
      },
    },
    {
      key: 'order',
      dataIndex: 'order',
      className: 'header-color-route',
      onCell: (record) => {
        const color = ColorByOrder(record.routeOrder);
        return {
          style: {
            background: color,
            width: '5px',
          },
        };
      },
    },
  ];

  return (
    <>
      <Table rowKey="id" pagination={false} columns={columns} dataSource={data} loading={loading} />
      {route && (
        <Assigment
          drawerVisible={drawerVisible}
          setDrawerAssigment={setAssigmentDrawer}
          routeOrder={route?.routeOrder}
          route={route}
        />
      )}
    </>
  );
}

TableContent.defaultProps = {
  data: [],
  loading: false,
  isPlannerView: false,
};

TableContent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.string,
      assignment: PropTypes.string,
      endsAt: PropTypes.string,
      id: PropTypes.number.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.number,
          lat: PropTypes.string,
          lng: PropTypes.string,
          type: PropTypes.string,
        })
      ),
      key: PropTypes.number,
      loads: PropTypes.arrayOf(
        PropTypes.shape({
          load: PropTypes.number,
        })
      ),
      route: PropTypes.number,
      startsAt: PropTypes.string,
      totalDistance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      totalStops: PropTypes.number,
      travelTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  loading: PropTypes.bool,
  isPlannerView: PropTypes.bool,
};

export default TableContent;
