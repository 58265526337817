import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  App,
  Avatar,
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Typography,
  Upload,
} from 'antd';
import PhoneInput from 'components/common/Form/PhoneInput';
import LocaleContext from 'components/locale/LocaleContext';
import { useUpdateUserMutation } from 'features/users/usersApiSlice';
import { selectCurrentUser, setCurrentUser } from 'features/users/userSlice';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const { Text } = Typography;

function MyProfileDrawer(props) {
  const [form] = Form.useForm();
  const [updateUser, { isLoading }] = useUpdateUserMutation();
  const { isVisible, setVisible } = props;
  const { id, nationalNid, givenName, lastName, photo, phone, email, organization, roles } =
    useSelector(selectCurrentUser);
  const [avatarUrl, setAvatarUrl] = useState(photo);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [fileList, setFileList] = useState([
    {
      uid: '-1',
      name: 'logo.png',
      status: 'done',
      url: photo,
    },
  ]);
  const { message } = App.useApp();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'profile' };
  const scopeFormI18n = { scope: 'form.profile' };
  const dispatch = useDispatch();
  const acceptedFormats = ['png', 'jpeg', 'jpg'];
  const initialValues = {
    avatar: photo,
    name: givenName,
    lastName,
    phonePrefix: phone?.substring(0, 3) || undefined,
    phoneNumber: phone?.substring(3) || '',
  };

  useEffect(() => {
    setVisible(isVisible);
  }, [isVisible, setVisible]);

  const resetForm = () => {
    form.setFieldsValue(initialValues);
  };

  const onClose = (event) => {
    event?.stopPropagation();
    setVisible(false);
    setAvatarUrl(initialValues.avatar);
    resetForm();
  };

  const hasChangedValues = (values) => {
    return Object.keys(initialValues).every((key) => initialValues[key] === values[key]);
  };

  const onFinish = async (values) => {
    if (hasChangedValues(values)) {
      message.warning(i18n.t('errors.noChangeRequested'), 4);
    } else {
      try {
        let formValues = {
          body: {
            name: values.name,
            lastName: values.lastName,
            phone: `${((values?.phoneNumber && values?.phonePrefix) || '').concat(
              (values?.phonePrefix && values?.phoneNumber) || ''
            )}`,
          },
          userId: id,
        };

        if (initialValues.avatar !== values.avatar) {
          formValues = {
            ...formValues,
            avatar: values.avatar,
          };
        }
        const result = await updateUser(formValues).unwrap();

        // Current user data is updated
        const userProfile = {
          id: result.id,
          nationalNid: result.nationalNid,
          givenName: result.name,
          lastName: result.lastName,
          fullName: `${result.name} ${result.lastName}`,
          photo: result.photo,
          phone: result.phone,
          email: result.email,
          subject: result.subject,
          organization: result.organization,
          organizations: result.organizations,
          roles: result.roles,
          actions: result.actions,
          enableTour: result.enableTour,
        };
        dispatch(setCurrentUser({ userProfile }));
        form.setFieldsValue({
          ...values,
          phonePrefix: result.phone?.substring(0, 3) || undefined,
          phoneNumber: result.phone?.substring(3) || '',
        });
        message.success(i18n.t('form.dataUpdated'), 4);
      } catch (error) {
        message.error(i18n.t('form.error'));
        console.error(error);
        resetForm();
      }
    }
    setVisible(false);
  };

  // Upload image handler methods
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const onRemove = () => {
    setAvatarUrl(initialValues.avatarUrl);
  };

  const handleChange = (info) => {
    setFileList(info.fileList);
    // Get this url from response in real world.
    getBase64(info.file.originFileObj, (url) => {
      setAvatarUrl(url);
    });
  };

  const dummyRequest = async (options) => {
    const { onSuccess } = options;
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const beforeUpload = (file) => {
    const isAccepted = acceptedFormats.includes(file.name.split('.').pop());
    if (!isAccepted) {
      message.error(
        i18n.t('form.errors.fileFormat', {
          fileName: file.name,
          formats: acceptedFormats.join(', '),
        })
      );
      onRemove();
    }
    return isAccepted;
  };

  return (
    <Drawer
      title={i18n.t('myProfile', scopeI18n)}
      width="700px"
      onClose={(event) => onClose(event)}
      open={isVisible}
      style={{ body: { paddingBottom: 80 } }}
      destroyOnClose
      maskClosable={false}
      closable={false}
      footer={
        <div className="footer-drawer-buttons">
          <Button onClick={onClose} disabled={isLoading}>
            {i18n.t('buttons.cancel')}
          </Button>
          <Button
            id="saveButton"
            loading={isLoading}
            form="my-profile"
            type="primary"
            htmlType="submit"
            style={{ marginLeft: '2rem' }}
            disabled={saveButtonDisabled}
          >
            {i18n.t('buttons.save')}
          </Button>
        </div>
      }
    >
      <div className="drawer-container">
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          name="my-profile"
          initialValues={initialValues}
          autoComplete="off"
          requiredMark="required"
        >
          <Row justify="center" align="middle">
            <Col className="drawer-avatar">
              <Avatar
                size={100}
                icon={<FontAwesomeIcon icon={['fa', 'user']} />}
                src={avatarUrl}
                alt={i18n.t('altAvatar', scopeI18n)}
              />
            </Col>
          </Row>
          <Row justify="center" align="middle">
            <Col>
              <Form.Item
                size="large"
                name="avatar"
                getValueFromEvent={({ file }) => file.originFileObj}
              >
                <Upload
                  fileList={fileList}
                  beforeUpload={beforeUpload}
                  name="avatar"
                  onChange={handleChange}
                  onRemove={onRemove}
                  accept=".png,.jpeg,.jpg"
                  maxCount={1}
                  showUploadList={false}
                  customRequest={dummyRequest}
                >
                  <Button>{i18n.t('buttons.changePhoto')}</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[24, 16]}>
            <Col span={12} xs={24} md={12}>
              <div style={{ margin: '0 0 8px' }}>
                <Text>DNI</Text>
              </div>
              <Input placeholder="No hay DNI definido" value={nationalNid || ''} disabled />
            </Col>
            <Col span={12} xs={24} md={12}>
              <Form.Item
                label={i18n.t('commons.name')}
                name="name"
                size="large"
                required
                rules={[
                  () => ({
                    validator(_, value) {
                      if (value) {
                        setSaveButtonDisabled(false);
                        return Promise.resolve();
                      }
                      setSaveButtonDisabled(true);
                      return Promise.reject(new Error(i18n.t('errors.name', scopeFormI18n)));
                    },
                  }),
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12} xs={24} md={12}>
              <Form.Item
                label={i18n.t('commons.lastname')}
                name="lastName"
                size="large"
                required
                rules={[
                  () => ({
                    validator(_, value) {
                      if (value) {
                        setSaveButtonDisabled(false);
                        return Promise.resolve();
                      }
                      setSaveButtonDisabled(true);
                      return Promise.reject(new Error(i18n.t('errors.lastName', scopeFormI18n)));
                    },
                  }),
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} md={12}>
              <PhoneInput />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12} xs={24} md={12}>
              <div style={{ margin: '0 0 8px' }}>
                <Text>{i18n.t('commons.email')}</Text>
              </div>
              <Input
                placeholder={i18n.t('errors.email', scopeFormI18n)}
                value={email || ''}
                style={{ margin: '0 0 24px' }}
                disabled
              />
            </Col>
            <Col span={12} xs={24} md={12}>
              <div style={{ margin: '0 0 8px' }}>
                <Text>{i18n.t('commons.roles')}</Text>
              </div>
              <Select
                mode="multiple"
                value={
                  roles?.map(function roleNameToArray(role) {
                    return role.name;
                  }) || []
                }
                disabled
                style={{ width: '100%' }}
                placeholder={i18n.t('errors.roles', scopeFormI18n)}
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12} xs={24} md={12}>
              <div style={{ margin: '0 0 8px' }}>
                <Text>{i18n.t('commons.relatedOrganizations')}</Text>
              </div>
              <Select
                mode="multiple"
                value={[organization?.name] || []}
                disabled
                style={{ width: '100%' }}
                placeholder={i18n.t('errors.relatedOrganizations', scopeFormI18n)}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </Drawer>
  );
}
MyProfileDrawer.defaultProps = {
  isVisible: false,
  setVisible: () => {},
};
MyProfileDrawer.propTypes = {
  isVisible: PropTypes.bool,
  setVisible: PropTypes.func,
};

export default MyProfileDrawer;
