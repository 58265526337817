import { App, Col, Row } from 'antd';
import AlertMap from 'components/common/AlertMap';
import BreadcrumbComponent from 'components/common/Breadcrumb';
import LeafletMap from 'components/common/Map/LeafletMap';
import { useGetDemandsSetQuery } from 'features/demandSets/demandSetsApiSlice';
import { useReverseGeocodeMutation } from 'features/georreference/georreferenceApiSlice';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import DemandsSection from './DemandsSection';
import PointsContext from './context/PointsContext';

import './style.css';

function DemandSetsEdit() {
  const { id } = useParams();
  const [demandSet, setDemandSet] = useState({});
  const [mapPoints, setMapPoints] = useState([]);
  const [collapseDemandId, setCollapseDemandId] = useState();
  const [moveToPosition, setMoveToPosition] = useState();
  const [addrFounded, setAddrFounded] = useState({});
  const [alertVisible, setAlertVisible] = useState(true);
  const { message } = App.useApp();

  // custom hooks
  const { data: demandSetData, refetch } = useGetDemandsSetQuery(id, {
    skip: id === undefined,
  });
  const [reverseGeocode] = useReverseGeocodeMutation();

  const fetchData = useCallback(async () => {
    try {
      const result = demandSetData;
      if (result) {
        setDemandSet(result);
        const points = result.demands.map((demandPoint) => {
          const lat = demandPoint.destination_latitude;
          const lng = demandPoint.destination_longitude;
          const { nid: Cod, name: Nombre } = demandPoint;
          const usedRows = ['Cod', 'Nombre'];
          return { key: demandPoint.id, lat, lng, Cod, Nombre, usedRows };
        });
        setMapPoints(points);
        refetch();
      }
    } catch (error) {
      console.error(error);
    }
  }, [demandSetData, refetch]);

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id, fetchData]);

  const breadcrumbItems = [
    {
      key: 'home',
      url: '/planning',
      label: 'Rutas',
    },
    {
      key: 'demands',
      url: '/planning',
      label: 'Conjunto de demandas',
    },
    {
      key: 'demand-sets',
      url: `/planning/demand-sets/${id}/edit`,
      label: 'Editar conjunto de demanda',
    },
  ];

  const onDrag = useCallback(
    (latLon, keyId) => {
      const assignCoords = async (latlng) => {
        const latF = latlng?.latForm.toString();
        const lngF = latlng?.lngForm.toString();
        const coords = `${latF},${lngF}`;
        message.open({
          key: 'loading-msg',
          type: 'loading',
          content: 'Actualizando ubicación...',
          duration: 2,
        });
        const client = 'demands-editor';
        const result = await reverseGeocode({ coords, client }).unwrap();
        if (result) {
          setAddrFounded({
            ...result,
            key: latlng.key,
            lat: latF,
            lng: lngF,
          });
          message.destroy('loading-msg');
          message.success('La ubicación ha sido actualizada, recuerda guardar los cambios');
        }
      };
      const { lat: latForm, lng: lngForm } = latLon;
      assignCoords({ latForm, lngForm, key: keyId });
      // update list of points
      setMapPoints([
        // disable other points to be draggable
        ...mapPoints.map((elem) => ({
          ...elem,
          draggable: keyId === elem.key,
          lat: keyId === elem.key ? latForm : elem.lat,
          lng: keyId === elem.key ? lngForm : elem.lng,
          onDrag: (latLong) => {
            onDrag(latLong, keyId);
          },
        })),
      ]);
    },
    [mapPoints, message, reverseGeocode]
  );

  const triggerCollapseCallback = (key) => {
    setCollapseDemandId(key);
    // draggable
    if (key.length > 0) {
      const keyId = parseInt(key[0], 10);
      const currPoint = mapPoints.find((pt) => pt.key === keyId);
      setMoveToPosition();
      if (currPoint) {
        const otherPoints = mapPoints.filter((pt) => pt.key !== keyId);
        setMapPoints([
          // disable other points to be draggable
          ...otherPoints.map((elem) => ({ ...elem, draggable: false })),
          {
            ...currPoint,
            draggable: true,
            onDrag: (latLon) => {
              onDrag(latLon, keyId);
            },
          },
        ]);
        setMoveToPosition([currPoint.lat, currPoint.lng]);
      }
    } else {
      // disable all points to be draggable
      setMapPoints([...mapPoints.map((elem) => ({ ...elem, draggable: false }))]);
      setMoveToPosition();
    }
  };

  const value = useMemo(
    () => ({ mapPoints, setMapPoints, collapseDemandId, addrFounded, onDrag, setMoveToPosition }),
    [addrFounded, collapseDemandId, mapPoints, onDrag]
  );
  const hideButtonEvent = () => setAlertVisible(false);

  return (
    Object.keys(demandSet).length > 0 && (
      <PointsContext.Provider value={value}>
        <div
          style={{
            height: '75vh',
          }}
        >
          <Row gutter={16}>
            <Col span={15}>
              <BreadcrumbComponent breadcrumbItems={breadcrumbItems} />
              <DemandsSection
                demandSet={demandSet}
                triggerCollapseCallback={triggerCollapseCallback}
              />
            </Col>
            <Col span={9}>
              {mapPoints && mapPoints.length > 0 ? (
                <div style={{ height: alertVisible ? '70vh' : '80vh' }}>
                  <AlertMap
                    alertVisible={alertVisible}
                    message="El pin coloreado corresponde a la ubicación que está consultando y la puede arrastrar para cambiar su posición."
                    hideButtonEvent={hideButtonEvent}
                  />
                  <LeafletMap
                    dataSource={mapPoints}
                    typeMarkers="draggable"
                    moveToPosition={moveToPosition}
                  />
                </div>
              ) : (
                ''
              )}
            </Col>
          </Row>
        </div>
      </PointsContext.Provider>
    )
  );
}

export default DemandSetsEdit;
