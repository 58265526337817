import {
  Alert,
  App,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Typography,
} from 'antd';
import AlertMap from 'components/common/AlertMap';
import AutocompleteAddress from 'components/common/AutocompleteAddress';
import Locations from 'components/common/Cascader/Locations';
import ContactForm from 'components/common/ContactForm';
import CustomAttributesPropTypes from 'components/common/CustomAttributes/customAttributes.propTypes';
import CaFormField from 'components/common/CustomAttributes/FormField';
import DynamicFields from 'components/common/Form/DynamicFields';
import Georreference from 'components/common/Georreference';
import LeafletMap from 'components/common/Map/LeafletMap';
import SelectPlusForm from 'components/common/SelectPlusForm';
import LocaleContext from 'components/locale/LocaleContext';
import { useReverseGeocodeMutation } from 'features/georreference/georreferenceApiSlice';
import {
  useCreateLocationMutation,
  useUpdateLocationMutation,
} from 'features/locations/locationsApiSlice';
import { selectCurrentOrganization, selectCurrentUser } from 'features/users/userSlice';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  BuildCustomAttributes,
  convertDateToDayjs,
  getOtherValueInForm,
  getRestrictionsForInitialValues,
  latitudeInvalid,
  longitudeInvalid,
} from 'services/repeated-functions';
import { formType, LocationsPropTypes } from 'types';

function LocationForm(props) {
  const {
    onCloseDrawer,
    location,
    caeFields,
    locations,
    type,
    form,
    setButtonLoading,
    drawerVisible,
    categories,
    refetchCategories,
    setTriggerClean,
    typeDrawer,
    createdFrom,
    enableMoveToPosition,
    listGroups,
  } = props;

  const [address, setAddress] = useState({
    ...location.addresses,
    country: location.addresses?.country,
    province: location.addresses?.province,
    city: location.addresses?.city,
    ...(location.addresses?.town && { town: location.addresses?.town }),
  });
  const [lat, setLat] = useState(location?.latitude || '-33.437060687304395');
  const [lng, setLng] = useState(location?.longitude || '-70.65057630171597');
  const moveToPosition = [lat, lng];
  const [mapPoints, setMapPoints] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alertVisible, setAlertVisible] = useState(true);

  const [createLocation] = useCreateLocationMutation();
  const [updateLocation] = useUpdateLocationMutation();
  const [reverseGeocode] = useReverseGeocodeMutation();
  const { id: userId } = useSelector(selectCurrentUser);
  const { message } = App.useApp();
  const { timezone: tzOrganization } = useSelector(selectCurrentOrganization);
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'form.location' };

  const isCodeDuplicated = (code) => {
    const siblings = locations?.filter((res) => res.nid !== location?.nid);
    return siblings.some((item) => item?.nid?.toLowerCase() === code?.toLowerCase());
  };

  let initialValues = {};

  const { dfFormatted, namesAndTypesFormatted } = getRestrictionsForInitialValues(location);

  const client = 'locations-editor';

  useEffect(() => {
    if (locations) {
      const basePoints = [];
      locations.map((loc) => {
        if (loc.id !== location.id) {
          basePoints.push({ key: loc.id, lat: loc.latitude, lng: loc.longitude });
        }
        return basePoints;
      });
      setMapPoints(basePoints);
      setLoading(false);
    }
  }, [location.id, locations]);

  const { Text } = Typography;

  const onFinish = async (values) => {
    setButtonLoading(true);
    const caes = BuildCustomAttributes(values);

    try {
      const {
        latForm: latitude,
        lngForm: longitude,
        nameForm: name,
        vipForm: vip,
        nidForm: nid,
        addressIdForm,
        addressStreetForm: street,
        addressAdicionalForm: line2,
        addressNumberForm: number,
        phones: phonesFromForm = [],
        emails,
        restrictions,
        contactId,
        contactName,
        category,
      } = values;

      const phones = phonesFromForm.map((ph) => ({ phone: `${ph.prefix}${ph.phone}` }));

      let body = {
        nid,
        name,
        latitude,
        longitude,
        vip,
        type: type === 'LOCATION' ? 'STOP' : type,
        addresses: {
          id: addressIdForm,
          country: address.country,
          province: address.province,
          city: address.city,
          town: address.town,
          street,
          line2,
          number,
        },
        customAttributeEntities: caes,
        contactId,
        contactName,
        contactPhones: phones,
        contactEmails: emails,
        restrictions,
        category,
      };

      if (caes.length === 0) {
        delete body.customAttributeEntities;
      }

      setTriggerClean(true);
      if (location.id) {
        if (!number && !street) {
          // no enviar address
          delete body.addresses;
        } else {
          body = {
            ...body,
            addresses: {
              ...body.addresses,
              ...(addressIdForm ? { updatedBy: userId } : { createdBy: userId }),
            },
          };
        }

        const { id: locationId } = location;
        await updateLocation({ locationId, body }).unwrap();
      } else {
        body = {
          ...body,
          addresses: {
            ...body.addresses,
            createdBy: userId,
          },
        };

        await createLocation(body).unwrap();
      }
      message.success(i18n.t('form.success'));
      if (type === 'LOCATION') {
        refetchCategories();
      }
      onCloseDrawer();
    } catch (error) {
      message.error(i18n.t('form.error'));
      console.error(error);
    }
    setTriggerClean(false);
    setButtonLoading(false);
  };

  const onFinishFailed = () => {
    message.error(i18n.t('form.checkForm'));
  };

  if (location.id) {
    const {
      addresses,
      latitude,
      longitude,
      name,
      nid,
      vip,
      contactPhones: phonesFromDb,
      contactEmails: emailsFromDb,
      customAttributes,
    } = location;
    const caFormatted = {};
    customAttributes?.forEach((ca) => {
      const { customValue } = ca;
      if (ca.dataType === 'date') {
        caFormatted[`ca[${ca.id}]`] = customValue?.value
          ? convertDateToDayjs(customValue?.value, tzOrganization)
          : undefined;
      } else if (ca.dataType === 'boolean') {
        caFormatted[`ca[${ca.id}]`] = customValue?.value === 'true';
      } else {
        caFormatted[`ca[${ca.id}]`] = customValue?.value;
      }
    });

    const phones = phonesFromDb
      ?.filter((item) => Object.keys(item).length)
      .map((ph) => ({
        prefix: ph.phone?.substring(0, 3) || '+56',
        phone: ph.phone?.substring(3),
      }));
    const emails = emailsFromDb?.filter((item) => Object.keys(item).length);
    initialValues = {
      ...location,
      nidForm: nid,
      nameForm: name,
      vipForm: vip,
      latForm: latitude,
      lngForm: longitude,
      addressIdForm: addresses?.id,
      addressStreetForm: addresses?.street,
      addressNumberForm: addresses?.number,
      addressAdicionalForm: addresses?.line2,
      phones,
      emails,
      ...caFormatted,
      ...dfFormatted,
      ...namesAndTypesFormatted,
    };
  } else {
    caeFields.forEach((ca) => {
      const { defaultValue } = ca;
      if (ca.dataType === 'date') {
        initialValues[`ca[${ca.id}]`] = defaultValue
          ? convertDateToDayjs(defaultValue, tzOrganization)
          : undefined;
      } else if (ca.dataType === 'boolean') {
        initialValues[`ca[${ca.id}]`] = defaultValue === 'true';
      } else {
        initialValues[`ca[${ca.id}]`] = defaultValue || undefined;
      }
    });
  }

  // reset form only if drawer is displayed
  useEffect(() => {
    if (drawerVisible) {
      form.resetFields();
    }
  }, [form, drawerVisible]);

  // Set address from cascader
  const handleAddressValues = (values) => {
    const [country, province, city, town] = values;
    setAddress((prevAddress) => ({ ...prevAddress, country, province, city, town }));
  };

  useEffect(() => {
    if (address) {
      form.setFieldsValue({
        addressStreetForm: address?.street,
        addressNumberForm: address?.number,
      });
    }
  }, [address, form]);

  const assignCoords = async (latlng, dragging = false) => {
    message.open({
      key: 'loading-msg',
      type: 'loading',
      content: i18n.t('updatingLocation', scopeI18n),
      duration: 2,
    });
    const latF = latlng?.latForm.toString();
    const lngF = latlng?.lngForm.toString();
    setLat(latF);
    setLng(lngF);
    form.setFieldsValue({
      latForm: latF,
      lngForm: lngF,
    });
    if (dragging) {
      const coords = `${latF},${lngF}`;
      const result = await reverseGeocode({ coords, client }).unwrap();
      if (result) {
        setAddress(result);
        message.destroy('loading-msg');
        message.success(i18n.t('locationUpdated', scopeI18n));
      }
    } else {
      // message.destroy('loading-msg');
    }
  };

  const hideButtonEvent = () => setAlertVisible(false);

  const renderMap = () => {
    let fullDataSource = [];

    // add curr point
    if (lat?.length > 4 && lng?.length > 4) {
      const key = location?.id || 'someId';
      fullDataSource.unshift({
        key,
        lat,
        lng,
        draggable: true,
        onDrag: (latLon) => {
          const { lat: latForm, lng: lngForm } = latLon;
          assignCoords({ latForm, lngForm }, true);
        },
      });
    }

    // add other points
    if (mapPoints.length > 0) {
      fullDataSource = fullDataSource.concat(mapPoints);
    }

    return !loading ? (
      <div
        style={{
          height: alertVisible ? '75vh' : '83vh',
        }}
      >
        <AlertMap
          alertVisible={alertVisible}
          message={i18n.t('alert.pinDraggable')}
          hideButtonEvent={hideButtonEvent}
        />
        <LeafletMap
          dataSource={fullDataSource}
          typeMarkers="draggable"
          moveToPosition={moveToPosition}
          enableScroll={false}
          enableMoveToPosition={enableMoveToPosition}
        />
      </div>
    ) : (
      ''
    );
  };

  const addressIsRequired = () => !location?.id || (address?.street && address.number);

  // Do change of coords, but no get address
  const manualChangeCoords = (e) => {
    const { name, value: newValue } = e.target;
    const otherCordsValue = getOtherValueInForm(name, form, ['latForm', 'lngForm']);
    if (!newValue || !otherCordsValue) {
      return;
    }
    let latitude;
    let longitude;
    if (name === 'latForm') {
      if (latitudeInvalid(newValue)) {
        return;
      }
      latitude = parseFloat(newValue);
      longitude = parseFloat(otherCordsValue);
    } else {
      if (longitudeInvalid(newValue)) {
        return;
      }
      latitude = parseFloat(otherCordsValue);
      longitude = parseFloat(newValue);
    }
    assignCoords({ latForm: latitude, lngForm: longitude });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      validateMessages={{ required: i18n.t('fieldRequired', scopeI18n) }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      initialValues={initialValues}
      scrollToFirstError
    >
      <div
        style={{
          height: '77vh',
        }}
      >
        <Row gutter={32}>
          <Col span={13}>
            <div
              style={{
                height: '80vh',
                overflow: 'auto',
                paddingRight: '1.5rem',
              }}
            >
              {typeDrawer === 'edit' && createdFrom === 'VisitList' && (
                <Alert
                  showIcon
                  message={i18n.t('alert.changeOnlyHere', scopeI18n)}
                  type="warning"
                  style={{ marginBottom: 10 }}
                  closable
                />
              )}
              <Row justify="space-between">
                <Form.Item name="restrictions" hidden>
                  <Input />
                </Form.Item>
                <Col span={11}>
                  <Form.Item
                    label={i18n.t('code', scopeI18n)}
                    name="nidForm"
                    rules={[
                      { required: true },
                      () => ({
                        validator(_, value) {
                          if (isCodeDuplicated(value)) {
                            return Promise.reject(
                              new Error(i18n.t('rules.code', { ...scopeI18n, code: value }))
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    label={i18n.t('name', scopeI18n)}
                    name="nameForm"
                    required
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <SelectPlusForm
                    form={form}
                    nameField="category"
                    selected={location.category}
                    label={i18n.t('category', scopeI18n)}
                    baseItems={categories.map(({ label }) => label)}
                  />
                </Col>
                <Col span={11} style={{ paddingTop: '1.5rem' }}>
                  <Form.Item name="vipForm" valuePropName="checked">
                    <Checkbox>{i18n.t('vip', scopeI18n)}</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <ContactForm />
              <Divider orientation="left" orientationMargin="0">
                <Text strong>{i18n.t('location', scopeI18n)}</Text>
              </Divider>
              <Row>
                <Col span={24}>
                  <AutocompleteAddress
                    setAddress={setAddress}
                    fromLocations
                    // move this to a function
                    customOnChange={assignCoords}
                    client={client}
                  />
                </Col>
              </Row>
              <Row justify="space-between">
                <Col span={24}>
                  <Form.Item label={i18n.t('localization', scopeI18n)} name="addressForm">
                    <Locations
                      country={address.country}
                      province={address.province}
                      city={address.city}
                      town={address.town}
                      customOnChange={(selected) => handleAddressValues(selected)}
                      placeholder={i18n.t('commons.select')}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col span={11}>
                  <Form.Item name="addressIdForm" hidden>
                    <Input name="addressIdForm" />
                  </Form.Item>
                  <Form.Item
                    label={i18n.t('address', scopeI18n)}
                    name="addressStreetForm"
                    rules={[{ required: addressIsRequired() }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    label={i18n.t('number', scopeI18n)}
                    name="addressNumberForm"
                    rules={[{ required: addressIsRequired() }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col span={11}>
                  <Form.Item label={i18n.t('address2', scopeI18n)} name="addressAdicionalForm">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={4} offset={7} style={{ textAlign: 'right', marginTop: '30px' }}>
                  <Georreference
                    locationCascade={[
                      address.country,
                      address.province,
                      address.city,
                      address.town,
                    ]}
                    customOnChange={assignCoords}
                  />
                </Col>
              </Row>
              <Divider orientation="left" orientationMargin="0">
                <Text strong>{i18n.t('geolocation', scopeI18n)}</Text>
              </Divider>
              <Row justify="space-between">
                <Col span={11}>
                  <Form.Item
                    label={i18n.t('latitude', scopeI18n)}
                    name="latForm"
                    required
                    rules={[
                      { required: true },
                      () => ({
                        validator(_, value) {
                          if (latitudeInvalid(value)) {
                            return Promise.reject(new Error('Latitud fuera de rango'));
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      placeholder={i18n.t('latitude', scopeI18n)}
                      name="latForm"
                      value={lat}
                      min={-90}
                      max={90}
                      style={{ width: '100%' }}
                      onBlur={manualChangeCoords}
                    />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    label={i18n.t('longitude', scopeI18n)}
                    name="lngForm"
                    required
                    rules={[
                      { required: true },
                      () => ({
                        validator(_, value) {
                          if (longitudeInvalid(value)) {
                            return Promise.reject(new Error('Longitud fuera de rango'));
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      placeholder={i18n.t('longitude', scopeI18n)}
                      name="lngForm"
                      value={lng}
                      min={-180}
                      max={180}
                      style={{ width: '100%' }}
                      onBlur={manualChangeCoords}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* restrictions */}
              <DynamicFields type={type} listGroups={listGroups} />
              {caeFields.length > 0 && (
                <Divider orientation="left" orientationMargin="0">
                  <Text strong>{i18n.t('additionalInformation', scopeI18n)}</Text>
                </Divider>
              )}
              <Row justify="space-between">
                {caeFields.map((caeField) => {
                  const { id } = caeField;
                  return (
                    <Col key={`ColCa${id}`} span={11} style={{ paddingLeft: 0 }}>
                      <CaFormField customAttribute={caeField} form={form} />
                    </Col>
                  );
                })}
              </Row>
            </div>
          </Col>
          <Col span={11} style={{ paddingLeft: '0' }}>
            {renderMap()}
          </Col>
        </Row>
      </div>
    </Form>
  );
}

LocationForm.propTypes = {
  onCloseDrawer: PropTypes.func,
  location: LocationsPropTypes,
  caeFields: PropTypes.arrayOf(CustomAttributesPropTypes),
  locations: PropTypes.arrayOf(LocationsPropTypes),
  type: PropTypes.string,
  form: formType,
  setButtonLoading: PropTypes.func,
  drawerVisible: PropTypes.bool,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  refetchCategories: PropTypes.func,
  setTriggerClean: PropTypes.func,
  typeDrawer: PropTypes.string,
  createdFrom: PropTypes.string,
  enableMoveToPosition: PropTypes.bool,
  listGroups: PropTypes.arrayOf(PropTypes.string),
};

LocationForm.defaultProps = {
  onCloseDrawer: () => {},
  location: {},
  caeFields: [],
  locations: [],
  type: 'DEPOT',
  form: {},
  setButtonLoading: () => {},
  drawerVisible: false,
  categories: [],
  refetchCategories: () => {},
  setTriggerClean: () => {},
  typeDrawer: '',
  createdFrom: '',
  enableMoveToPosition: true,
  listGroups: [],
};

export default LocationForm;
